.about-container {
    width: 100%;
    height: 100%;
    background-color: #031525;
  }

  .about-content {
    color: #D3E3FD;
    background-color: #031525;
    
  }

  .about-title {
    color: #D3E3FD;
    background-color: #031525;
    text-align: center;
  }

  .about-section-title {
    text-align: center;
    
  }

  .about-section {
    color: #D3E3FD;
    text-align: center;
    padding: 20px;
    
  }

  

  