.faq-screen-container {
    width: 100%;
    height: 100vh;
    background-color: #031525;
  }

  .faq-screen-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; 
    height: 100%;
    padding: 20px;
    background-color: #031525;
    
  }
  
  .faq-screen-title {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    color: #3d9970;
    margin-bottom: 20px;
  }
  
  .faq-screen-description {
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    color: #D3E3FD;
    margin-bottom: 20px;
  }
  
  