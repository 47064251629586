/* styles.css */
.registration-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: relative; /* Container for the steps */
    overflow: hidden; 
    
   
}

.steps-container {
  margin-top: 20px;
  position: relative; /* Needed for absolute children positioning */
width: 100%; 
}
.contentContainer {
  position: absolute; /* Absolutely position each step */
  top: 0;
  
  width: 100%;
  transition: transform 0.5s ease-in-out;
  transform: translateX(100%); /* Moves initially off-screen to the right */
}
  
  .active {
    transform: translateX(0); /* Active step in view */
  }
  
  /* Outgoing step class */
  .outgoing {
    transform: translateX(-100%); /* Slide the outgoing step to the left */
  }
  
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
  }
  
  .registration-title {
    font-size: 20px;
    margin-bottom: 20px;
    color: #3D9970
  }

  .registration-subtitle {
    font-size: 30px;
    margin-bottom: 20px;
    color: #3D9970;
    text-align: center;
  }
  
  .registration-input {
    font-size: 20px;
    text-align: 'center';
    background-color: transparent; /* Make the background transparent */
    border: 1px solid #000; /* Example border: black and 1px wide */
    margin: 5px;
    color: black; /* Text color */
    padding: 10px;
    width: 40%; /* Padding inside the input */
     /* Font size */
    border-radius: 5px; /* Rounded corners for the border */
    outline: none;
  }
  
  .registration-input::placeholder {
    color: black; /* Change to your desired color */
    opacity: 1; /* Optional: Adjust the opacity of the placeholder text */
  }
  .registration-button {
    background-color: #233565;
    padding: 5px;
    border-radius: 10px;
    color: #D3E3FD;
    font-size: 30px;
    width: 40%;
  }

  .registration-button:active {
    background-color: #D3E3FD;
    padding: 5px;
    border-radius: 10px;
    color: #233565;
    font-size: 30px;
    width: 40%;
  }

  .registration-button-dashboard {
    background-color: #233565;
    padding: 5px;
    border-radius: 10px;
    color: #D3E3FD;
    font-size: 30px;
    width: 80%;
  }

  .registration-button-dashboard:active {
    background-color: #D3E3FD;
    padding: 5px;
    border-radius: 10px;
    color: #233565;
    font-size: 30px;
    width: 80%;
  }

  .registration-button-container {
    display: flex;
    
    justify-content: space-between;
    margin-top: 20px;
    width: 40%;
    /* Additional container styles */
  }

  .registration-button-container-dashboard {
    display: flex;
    
    justify-content: center;
    margin-top: 20px;
    width: 40%;
    /* Additional container styles */
  }
  
  .datePicker {
    /* Date picker styles */
  }
  
  .registration-avatar {
    width: 120px;
    height: 120px;
    /* Additional avatar styles */
  }

  
  
  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #3d9970;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
    margin: auto;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  

  .error-message {
    text-align: center;
    color: red;
    margin-top: 10px;
  }


  @media (max-width: 768px) {}

  @media (max-width: 867px) {
    .container {
      width: 100%;
    }
    .registration-input {
      font-size: 20px;
      text-align: 'center';
      background-color: transparent; /* Make the background transparent */
      border: 1px solid #000; /* Example border: black and 1px wide */
      margin: 5px;
      color: black; /* Text color */
      padding: 10px;
      width: 80%; /* Padding inside the input */
       /* Font size */
      border-radius: 5px; /* Rounded corners for the border */
      outline: none;
    }
    
    .registration-input::placeholder {
      color: black; /* Change to your desired color */
      opacity: 1; /* Optional: Adjust the opacity of the placeholder text */
    }
    .registration-button {
      background-color: #233565;
      padding: 5px;
      border-radius: 10px;
      color: #fff;
      font-size: 30px;
      width: 40%;
    }
  
    .registration-button-container {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      width: 80%;
      /* Additional container styles */
    }

    .registration-subtitle-dashboard {
      font-size: 30px;
      margin-bottom: 20px;
      color: #3d9970;
      text-align: center;
    }

    .registration-button-dashboard {
      background-color: #233565;
      padding: 5px;
      border-radius: 10px;
      color: #fff;
      font-size: 30px;
      width: 80%;
    }
  
    .registration-button-container-dashboard {
      display: flex;
      
      justify-content: center;
      margin-top: 20px;
      width: 80%;
      /* Additional container styles */
    }
  }