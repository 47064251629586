/* WelcomeScreen.css */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 30s linear infinite;
}

@keyframes spin-reverse {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.spin-reverse {
  animation: spin-reverse 30s linear infinite;
}



.swiper-slide.swiper-slide-active {
  
}

.user-testimonial-card {
  
  width: 100%; 
  padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #D3E3FD;
    background-color: #0D2136;
    border-radius: 40px;
    border-left: 1px solid #D3E3FD;
    border-right: 1px solid #D3E3FD;
    /* box-shadow: 0 10px 6px #4F647E; */
    margin: 10px;
    height: 400px;
    width: 500px;
}

.mySwiper1 {
  display: flex;
  align-items: center;
  width: 625px !important;
  height: 500px !important;
  
}

/* Keep your existing slide-in and slide-out keyframes */






.welcome-container {
   
  min-height: 100vh; /* 100% of the viewport height */
  background-color: #031525;
}


.spikes {
  --spike-color: #031525;
  --spike-width: 100%;
  --spike-height: 100px;
  position: relative;
  /* background-color: #233565; */
  background: linear-gradient(
    to right,
    #b4c2ce,
      #3896d4
  );

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
 padding-block: 40px;
 width: 100%;
 
}

.spikes::before, .spikes::after {
  
  content: '';
  position: absolute;
  width: 100%;
  height: var(--spike-height);
  background: var(--spike-color);
  -webkit-mask-image :url('../../assets/trapezoid.svg');
  -webkit-mask-size: var(--spike-width) var(--spike-height);
  
  /* -webkit-mask-repeat: no-repeat; */
}

.spikes::before{
  top: 0
}

.spikes::after{
bottom: 0;
transform: rotate(.5turn);
}

.spikes2 {
  --spike-color: #031525;
  --spike-width: 100%;
  --spike-height: 100px;
  position: relative;
  border-top: 5px solid #4F647E;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  padding-block: 40px;
  width: 100%;
}

.spikes2::before, .spikes2::after {
  content: '';
  position: absolute;
  width: 100%;
  height: var(--spike-height);
  background: var(--spike-color);
  -webkit-mask-image :url('../../assets/trapezoid.svg');
  -webkit-mask-size: var(--spike-width) var(--spike-height);
  /* -webkit-mask-repeat: no-repeat; */
}

.spikes2::before{
  top: 0
}

.spikes2::after{
bottom: 0;
transform: rotate(.5turn);
}

.spikes-reverse {
  --spike-color: #031525;
  --spike-width: 100%;
  --spike-height: 100px;
  position: relative;
  border-top: 5px solid #4F647E;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding-block: 40px;
  width: 100%;
}

.spikes-reverse::before, .spikes-reverse::after {
  content: '';
  position: absolute;
  width: 100%;
  height: var(--spike-height);
  background: var(--spike-color);
  -webkit-mask-image :url('../../assets/trapezoid.svg');
  -webkit-mask-size: var(--spike-width) var(--spike-height);
  /* -webkit-mask-repeat: no-repeat; */
}

.spikes-reverse::before{
  top: 0
}

.spikes-reverse::after{
bottom: 0;
transform: rotate(.5turn);
}

.welcome-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;

}

.welcome-top-container{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  /* align-content: center; */
  overflow: hidden;
  align-items: center;
  /* position: relative;
  background-image: url('/public/Math.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; */
  width: 100%;
  /* border-bottom: solid 1px #4F647E; */
  /* margin-bottom: 20px; */
}

.welcome-top-symbols, .welcome-bottom-symbols {
  /* line-height: 1;
  height: auto;
  width: auto;
  margin: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle; */
}

.addition-symbol {
  font-size: 150px;
  color: rgb(173, 58, 58);

}
.multiplication-symbol {
  font-size: 150px;
  color: rgb(55, 106, 245);
}

.subtraction-symbol {
  font-size: 150px;
  color: rgb(229, 231, 96);
}

.division-symbol {
  font-size: 150px;
  color: rgb(68, 228, 89);
}
.integral-symbol {
  font-size: 150px;
  color: rgb(201, 68, 228);
}
.function-symbol {
  font-size: 150px;
  color: rgb(68, 223, 228);
}
.sum-symbol {
  font-size: 150px;
  color: rgb(228, 185, 68);
}
.percent-symbol {
  font-size: 150px;
  color: rgb(143, 68, 228);
}
.infinity-symbol {
  font-size: 150px;
  color: rgb(68, 228, 89);
}
.equals-symbol {
  font-size: 150px;
  color: rgb(173, 58, 58);
}
.pound-symbol {
  font-size: 150px;
  color: rgb(201, 68, 228);
}

.squareroot-symbol {
  font-size: 150px;
  color: rgb(129, 68, 228);
}

.addition-symbol,
  .multiplication-symbol,
  .subtraction-symbol,
  .division-symbol,
  .integral-symbol,
  .function-symbol,
  .sum-symbol,
  .percent-symbol,
  .infinity-symbol,
  .equals-symbol,
  .pound-symbol,
  .squareroot-symbol {
    line-height: 1;
  
  }


/* .welcome-top-container::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7); 
  z-index: 1;
} */

/* Add this to any content inside the container to ensure it is displayed above the overlay */
.content {
  position: relative;
  z-index: 2;
}

.welcome-registration-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 20px;
  border-left: solid 2px #4F647E;
  border-right: solid 2px #4F647E;
  width: 50%;
  
}
.welcome-registration-title {
  color: #D3E3FD;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  
}
.welcome-registration-subtitle {
  color: #D3E3FD;
  font-size: 30px;
}
.welcome-button-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.welcome-button {
  background: linear-gradient(
    to right,
    #df5959,
    #9a22b3
  );
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  font-size: 30px;
}
.label {
  color: #D3E3FD;
  font-size: 30px;
}

/* /////////////////////////////////////// */
.welcome-subscription{
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
  margin-bottom: 20px;
}
.welcome-subscription-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 20px;
  /* border: solid 2px #fff; */
  width: 100%;
  
}

.welcome-subscription-title {
  color: #D3E3FD;
  font-size: 25px;
  
}
.welcome-subscription-label {
  color: #D3E3FD;
  font-size: 20px;
}

/* //////////////////////////////////////////////////////// */
.parents-kids-container {
  padding-top: 100px;
  padding-bottom: 100px;
  padding-left: 30px;
  padding-right: 30px;
  
}

.swiper {
  width: 90%;
  height: 70%;
  /* background: #0D2136; */
  background: #0D2136;
  border-radius: 45px;
}

.swiper-slide {
  font-size: 18px;
  color: #D3E3FD;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 15px 50px;
  /* height: auto; */
  
}

.parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 130%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}

.swiper-slide .title {
  font-size: 25px;
  /* font-weight: 300; */
  font-weight: bold;
}

.swiper-slide .subtitle {
  font-size: 22px;
}

.swiper-slide .text {
  font-size: 18px;
  max-width: 400px;
  line-height: 1.3;
  margin-right: 5px;
}

.slide-container {
  display: flex;
  flex-direction: column;
  color: #D3E3FD;
  background-color: #0D2136;
  align-items: center;
  /* background-image: url('../../../public/card1.webp'); */
  /* background-size: cover; */
}

.slide-content {
  display: flex;
  flex-direction: row;
  
}

.slide-text-content {
  display: flex;
  flex-direction: column;
}



.image-container {
  display: flex;
  align-items: center;
  width: auto; /* Adjust the width as needed */
  height: auto; /* Adjust the height as needed */
  object-fit: contain; /* or 'cover' depending on your preference */

}

.parentscardimage1 {
  border-radius: 20px;
  
  width: 200px;
  height: 150px;
}

.parentscardimage2 {
  border-radius: 20px;
  
  width: 200px;
  height: 200px;
}

.parentscardimage3 {
  border-radius: 20px;
  
  width: 175px;
  height: 200px;
}

.parentscardimage4 {
  border-radius: 20px;
  
  width: 175px;
  height: 200px;
}
.kidscardimage1 {
  border-radius: 20px;
  
  width: 200px;
  height: 200px;
}
.kidscardimage2 {
  border-radius: 20px;
  padding-top: 20px;
  width: 200px;
  height: 160px;
}

.kidscardimage3 {
  border-radius: 20px;
  
  width: 175px;
  height: 200px;
}

.kidscardimage4 {
  border-radius: 20px;
  
  width: 175px;
  height: 200px;
}
.parents-information, .kids-information {
  /* display: flex;
  flex-direction: column;
  z-index: 1; */
  /* color: #0D2136; */
  background-color: #0D2136;
  border-radius: 40px;
  /* border-left: 12px solid #0D2136;
  border-right: 12px solid #0D2136; */
  box-shadow: 0 10px 6px #4F647E;
  margin: 10px;
  padding: 0px;
  width: 50%;
  height: 400px;
}

.parents-info-title, .kids-info-title {
  text-align: center;
  font-size: 45px;
  font-weight: bold;
  color: #D3E3FD;
  padding-top: 20px
}



.welcome-card {
  padding: 20px;
  margin: 10px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
  /* Add more styles as needed */
}



/* /////////////////////////////////////// */
.welcome-steps {
  
  display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 100%;
margin-bottom: 20px;

}
.welcome-steps-container {
  display: flex;
flex-direction: column;

width: 1100px;
padding: 20px;

background-color: #0D2136;
  border-radius: 40px;
  /* border-left: 12px solid #0D2136;
  border-right: 12px solid #0D2136; */
  box-shadow: 0 10px 6px #4F647E;
}

.welcome-steps-title-container {
display: flex;
justify-content: center;
}

.welcome-steps-title {
color: #D3E3FD;
font-size: 40px;
}

.welcome-steps-subtitle {
  color: #3D9970;
}

.welcome-steps-text {
  color: #D3E3FD;
  font-size: 22px;
}

.steps-number1 {
  color: rgb(173, 58, 58);
  margin-right: 5px;
  font-weight: bold;
  font-size: 25px;
}
.steps-number2 {
  color: rgb(55, 106, 245);
  margin-right: 5px;
  font-weight: bold;
  font-size: 25px;
}
.steps-number3 {
  color: rgb(229, 231, 96);
  margin-right: 5px;
  font-weight: bold;
  font-size: 25px;
}
.steps-number4 {
  color: rgb(68, 228, 89);
  margin-right: 5px;
  font-weight: bold;
  font-size: 25px;
}

.settings-link-welcome {
  background-color: #3D9970;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 5px;
  font-size: 15px;
  border: none;
  cursor: pointer;
  text-align: center;
  width: 50%;
}

.settings-link-welcome:hover {
background-color: #D3E3FD;
color: #031525;
}

.settings-link-welcome:active {
  background-color: #D3E3FD;
  color: #3D9970;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 5px;
  font-size: 15px;
  border: none;
  cursor: pointer;
  text-align: center;
}

.welcome-steps-button-container {
  display: flex;
  justify-content: center;
}

/* /////////////////////////////////////////////// */

.testimonials-title {
   font-size: 40px;
}
.testimonials-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  color: #D3E3FD;
  background-color: #0D2136;
  box-shadow: 0 10px 6px #4F647E;
  /* border: 1px solid #df5959; */
  /* border: 1px solid #3d9970; */
  border-radius: 40px;
  /* box-shadow: 0 0px 15px #4F647E; */
  padding: 10px;
  margin: 10px;
  width: 70%;
  height:550px;
}

.user-testimonial-title {
  text-align: center;
  /* background-color: blue; */
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  width: 100%;
  font-size: 30px;
}

.user-testimonial-comment {
  text-align: left;
  font-size: 20px;
}

.signature {
  text-align: center;
}

.rating {
  color: gold;
  font-size: 50px; /* Adjust size as needed */
}



/* //////////////////////////////////////////////// */

.image{
  max-width: 400px; /* Reduce the image size */
  height: auto; /* Keep aspect ratio */
  margin: 10px 0; /* Less margin around images */
  border-radius: 30px;
  z-index: 1;
}

.welcome-info-title1 {
  color: #D3E3FD;
  font-weight: bold;
  font-size: 50px;
  
}

.welcome-info-label1 {
  color: #D3E3FD;
  font-size: 30px;
}
/* /////////////////////////////////////// */

.welcome-info-title2 {
  color: #D3E3FD;
  font-weight: bold;
  font-size: 50px;
  
}

.welcome-info-label2 {
  color: #D3E3FD;
  font-size: 30px;
}
/* /////////////////////////////////////// */

.welcome-info-title3 {
  color: #D3E3FD;
  font-weight: bold;
  font-size: 50px;
  
}

.welcome-info-label3 {
  color: #D3E3FD;
  font-size: 30px;
}
/* ////////////////////////////////////////////// */

.welcome-faq-container{
  display: flex;
  flex-direction: column;
  width: auto;
  align-items: center;
  margin-top: 20px;
  /* border-top: solid 2px #3d9970; */
  width: 100%;
  margin-bottom: 15px;
  
}

.info-container {
  width: auto;
}

.welcome-subscription-info-container1, .welcome-subscription-info-container2, .welcome-subscription-info-container3 {
  height: 500px;
  width: 350px;
}

.welcome-subscription-label-container {
  height: 325px;
}

.welcome-subscription-info-container1 {
  display: flex;
  flex-direction: column;
  
  /* align-items: center; */
  z-index: 1;
  color: #D3E3FD;
  background-color: #0D2136;
  border: 1px solid #3d9970;
  /* border: 1px solid #3d9970; */
  border-radius: 40px;
  box-shadow: 0 10px 6px #4F647E;
  /* padding: 10px; */
  margin: 10px;
}
.welcome-subscription-info-container2 {
  display: flex;
  flex-direction: column;
  
  /* align-items: center; */
  z-index: 1;
  color: #D3E3FD;
  background-color: #0D2136;
  border: 1px solid #233565;
  /* border: 1px solid #3d9970; */
  border-radius: 40px;
  box-shadow: 0 10px 6px #4F647E;
  /* padding: 10px; */
  margin: 10px;
}
.welcome-subscription-info-container3 {
  display: flex;
  flex-direction: column;
  
  /* align-items: center; */
  z-index: 1;
  color: #D3E3FD;
  background-color: #0D2136;
  border: 1px solid #df5959;
  /* border: 1px solid #3d9970; */
  border-radius: 40px;
  box-shadow: 0 10px 6px #4F647E;
  /* padding: 10px; */
  margin: 10px;
}

.welcome-subscription-info-title1 {
  color: #D3E3FD;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  background-color: #3d9970;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;

}
.welcome-subscription-info-title2 {
  color: #D3E3FD;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  background-color: #233565;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;

}
.welcome-subscription-info-title3 {
  color: #D3E3FD;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  background: linear-gradient(
    to right,
    #df5959,
    #9a22b3
  );
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;

}

.welcome-subscription-info-label1 {
  font-size: 18px;
  margin-left: 15px;
  margin-right: 15px
}

.welcome-subscription-info-label2 {
  text-align: center;
  font-size: 20px;
  margin-left: 15px;
  margin-right: 15px;
  text-align: left;
  width: 300px;
}

.welcome-subscription-info-price {
  font-size: 30px;
  font-weight: bold;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
}

.welcome-subscription-settings-link {
  text-align: center;
}

.green-checkmark {
  color: #00B69F;
  margin-right: 10px;
}
.red-checkmark {
  color: #D11D5E;
  margin-right: 10px;
}

.green-price {
  font-size: 20px;
  font-weight: bold;
  color: #00B69F;
  margin-left: 10px;
}

.red-price {
  font-size: 20px;
  font-weight: bold;
  color: #D11D5E;
  margin-left: 10px;
}





@media (max-width: 448px) { /* Example for tablets and smaller devices */
  
  .swiper {
    width: 90%;
    height: 80%;
    background: #0D2136;
    border-radius: 45px;
    /* border: 1px solid white; */
    padding-left: 0px;
  }
  
  .swiper-slide {
    font-size: 18px;
    color: #D3E3FD;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding-left: 50px;
    height: 60%;
    
  }
  
  .parallax-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 130%;
    height: 100%;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
  }
  
  .swiper-slide .title {
    font-size: 20px;
    /* font-weight: 300; */
    font-weight: bold;
  }
  
  .swiper-slide .subtitle {
    font-size: 22px;
  }
  
  .swiper-slide .text {
    font-size: 16px;
    max-width: 400px;
    line-height: 1.3;
  }

  .testimonials-container {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    background-color: transparent;
    /* border: 1px solid #df5959; */
    /* border: 1px solid #3d9970; */
    /* border-radius: 40px; */
    /* box-shadow: 0 0px 15px #4F647E; */
    padding: 0px;
    margin: 10px;
    width: 95%;
    height:550px;
  }
  .user-testimonial-card {
  
    width: 95%; 
    height: 95%;
    /* padding: 20px; */
      display: flex;
      flex-direction: column;
      
      color: #D3E3FD;
      background-color: #0D2136;
      border-radius: 40px;
      /* box-shadow: 0 10px 6px #4F647E; */
      /* margin: 10px; */
      /* height: 400px; */
      /* width: 500px; */
  }
  .user-testimonial-comment {
    width: 100%;
    font-size: 17px;
  }

  .signature {
    text-align: center;
  }

  .mySwiper1 {
    width: 100% !important
  }

  .parents-kids-container {
    flex-direction: column;
    height: auto;
  }
  
  .parents-information, .kids-information {
    
   
    /* margin: 10px; */
    width: 100%;
    height: 550px;
  }
  
  .parents-info-title, .kids-info-title {
    text-align: center;
    font-size: 30px;
    color: #D3E3FD;
  }

  .slide-container {
    padding: 10px;
    margin: 10px;
    text-align: center;
    width: 100%;
  }

  .slide-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .slide-text-content {
    display: flex;
    flex-direction: column;
  }
  
  .parentscardimage1 {
    border-radius: 20px;
    
    width: 175px;
    height: 125px;
  }
  
  .parentscardimage2 {
    border-radius: 20px;
    
    width: 150px;
    height: 150px;
  }
  .parentscardimage3 {
    border-radius: 20px;
    
    width: 150px;
    height: 150px;
  }
  .parentscardimage4 {
    border-radius: 20px;
    
    width: 100px;
    height: 125px;
  }
  .kidscardimage1 {
    border-radius: 20px;
    
    width: 175px;
    height: 150px;
  }
  .kidscardimage2 {
    border-radius: 20px;
    padding-top: 20px;
    width: 200px;
    height: 160px;
  }
  .kidscardimage3 {
    border-radius: 20px;
    
    width: 150px;
    height: 175px;
  }
  .kidscardimage4 {
    border-radius: 20px;
    
    width: 125px;
    height: 150px;
  }

 
  /* //////////////////////////////////// */ 
  
/* /////////////////////////////////////// */
.welcome-steps {
  
  display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 100%;
margin-bottom: 20px;

}
.welcome-steps-container {
  display: flex;
flex-direction: column;

width: 350px;
padding: 20px;

background-color: #0D2136;
  border-radius: 40px;
  /* border-left: 12px solid #0D2136;
  border-right: 12px solid #0D2136; */
  box-shadow: 0 10px 6px #4F647E;
}

.welcome-steps-title-container {
display: flex;
justify-content: center;
}

.welcome-steps-title {
color: #D3E3FD;
font-size: 30px;
}

.welcome-steps-subtitle {
  color: #3D9970;
}

.welcome-steps-text {
  color: #D3E3FD;
  font-size: 18px;
}

.steps-number1 {
  color: rgb(173, 58, 58);
  margin-right: 5px;
  font-weight: bold;
  font-size: 22px;
}
.steps-number2 {
  color: rgb(55, 106, 245);
  margin-right: 5px;
  font-weight: bold;
  font-size: 22px;
}
.steps-number3 {
  color: rgb(229, 231, 96);
  margin-right: 5px;
  font-weight: bold;
  font-size: 22px;
}
.steps-number4 {
  color: rgb(68, 228, 89);
  margin-right: 5px;
  font-weight: bold;
  font-size: 22px;
}


/* /////////////////////////////////////////////// */
  

  .welcome-registration-title{
    text-align: center;
    font-size: 20px;
    font-weight: bold;
  }

  .welcome-registration-subtitle {
    text-align: center;
    font-size: 30px;
  }

  .welcome-subscription-title {
    font-size: 20px;
    text-align: center;
  }
  .welcome-subscription-label{
    font-size: 15px;
    text-align: center;
  }

  .welcome-faq-container {
    display: flex;
    flex-direction: column-reverse;
     /* Reduce width to provide padding on the sides */
    margin-top: 10px; /* Less space on top */
  }

  .welcome-info-title1,
  .welcome-info-title2,
  .welcome-info-title3,
  .welcome-info-title4 {
    text-align: center;
    font-size: 25px; /* Smaller font size for titles */
    
    color: #D3E3FD;
  }

  .welcome-info-label1,
  .welcome-info-label2,
  .welcome-info-label3,
  .welcome-info-label4 {
    text-align: center;
    font-size: 20px; /* Smaller font size for labels */
    color: #D3E3FD
  }

  .welcome-settings-link {
    text-align: center;
    font-size: 15px; /* Smaller font size for labels */
  }

.spikes, .spikes2 {
  padding-left: 10px;
  padding-right: 10px;
}

  .image {
    max-width: 40%; /* Reduce the image size */
    height: auto; /* Keep aspect ratio */
    
  }

  .info-container {
    z-index: 1;
    background-color: #0D2136;
    border-radius: 20px;
    padding: 10px;
    margin-left: 10px;
    margin-right: 10px;
    box-shadow: 0px 6px 10px #4F647E;
    
  }
  
.welcome-subscription {
display: flex;
flex-direction: column;
}

  .welcome-subscription-info-container {
    
    margin: 20px;
  }
  
  .welcome-subscription-info-title1 {
    font-size: 30px;
  }
  
  .welcome-subscription-info-label1 {
    font-size: 20px;
  }

  .welcome-top-container{
    justify-content: center;
    flex-wrap: wrap;
    
  }

  .welcome-registration-container {
    width: 95%;
    padding: 10px;
    margin: 0px;
    border-left: none;
    border-right: none;
    border-top: 1px solid #4F647E;
    border-bottom: 1px solid #4F647E;
  }
  .addition-symbol {
    font-size: 50px;
    color: rgb(173, 58, 58);
  
  }
  .multiplication-symbol {
    font-size: 50px;
    color: rgb(55, 106, 245);
  }
  
  .subtraction-symbol {
    font-size: 70px;
    color: rgb(229, 231, 96);
  }
  
  .division-symbol {
    font-size: 70px;
    color: rgb(68, 228, 89);
  }
  .integral-symbol {
    font-size: 70px;
    color: rgb(201, 68, 228);
  }
  .function-symbol {
    font-size: 70px;
    color: rgb(68, 223, 228);
  }
  .sum-symbol {
    font-size: 70px;
    color: rgb(228, 185, 68);
  }
  .percent-symbol {
    font-size: 70px;
    color: rgb(143, 68, 228);
  }
  .infinity-symbol {
    font-size: 70px;
    color: rgb(68, 228, 89);
  }
  .equals-symbol {
    font-size: 70px;
    color: rgb(173, 58, 58);
  }
  .pound-symbol {
    font-size: 70px;
    color: rgb(201, 68, 228);
  }
  
  .squareroot-symbol {
    font-size: 70px;
    color: rgb(129, 68, 228);
  }

  .addition-symbol,
  .multiplication-symbol,
  .subtraction-symbol,
  .division-symbol,
  .integral-symbol,
  .function-symbol,
  .sum-symbol,
  .percent-symbol,
  .infinity-symbol,
  .equals-symbol,
  .pound-symbol,
  .squareroot-symbol {
    /* line-height: 1; */
  height: auto;
  width: auto;
  margin: 0;
  padding: 15px;
  display: inline-block;
  vertical-align: middle;
    
  }

  .welcome-top-symbols, .welcome-bottom-symbols {
    
  }
}