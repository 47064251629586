.rewards-container {
    background-color: #031525;
    height: 100%;
  }

  .rewards-title {
    font-size: 40px;
    text-align: center;
    color: #3D9970;
  }
  .rewards-info-container {
    background-color: #0D2136;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .rewards-info {
    text-align: center;
    /* text-wrap: wrap; */
    font-size: 20px;
    color: #D3E3FD;
    width: 80%;
  }

  .rewards-action {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 60%;
    border-top: 1px solid #D3E3FD;
  }

  .rewards-balance {
    font-size: 30px;
    color: #3D9970;
  }

  .rewards-order-button {
    background-color: #3D9970;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 25px;
    border: none;
    cursor: pointer;
    text-align: center;
    box-shadow: 0 3px 2px gray;
  }

  .rewards-order-button:hover {
    background-color: #D3E3FD;
    color: #031525;
  }

  .rewards-order-button:active {
    background-color: #D3E3FD;
    color: #031525;
  }

  .search-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding-top: 20px;
    color: #D3E3FD;
    
  }
  .rewards-info1 { 
  color: red;
  text-align: center;
  }
  .search-text {
    margin-right: 10px;
    font-size: 25px;
  }
  .search-input {
    border-radius: 30px;
    padding-left: 10px;
    font-size: 20px;
  }
  .search-input::placeholder {
    padding-left: 10px;
    font-size: 20px;
  }
  
  .brands-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Adjust minmax values as per your design */
    gap: 20px; /* Space between grid items */
    padding: 20px;
  }
  
  .brand {
    background-color: #0D2136 !important;
    box-shadow: 0 3px 2px #4F647E;
    /* border: 1px solid #ccc;  */
    padding: 15px;
    border-radius: 10px; /* Optional: for rounded corners */
    background-color: white; /* Optional: for background */
    height: auto;
  }

.brand-name {
  text-align: center;
  color: #D3E3FD;
  font-size: 20px;
}

.values-toggle {
  color: #D3E3FD;
}

.values {
  font-size: 16px;
  color: #D3E3FD;
}
  
  
  .brand img {
    max-width: 100%; /* Ensures image does not overflow its container */
    height: auto;
    margin-bottom: 10px;
  }
  
  .items {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Adjust for item cards */
    gap: 10px; /* Space between items */
    margin-top: 20px;
  }
  
  .item {
    color: #D3E3FD;
    /* border: 1px solid #ddd;  */
    padding: 2px;
    border-radius: 5px; /* Optional: for rounded corners */
    text-align: center; /* Optional: if you want text centered */
  }
  

  /* ORDER MODAL //////////////////////////////////////////////////////////
   */

   .redeem-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .redeem-modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #031525;
    padding: 20px;
    border-radius: 4px;
    position: relative;
    width: 400px;
    height: 600px;
    border-radius: 30px;
    /* More styling */
  }

  /* NumberPadButton.css */
  .modal-loading {
    font-size: 30px;
    color: #8AB4F8
  }

  .redeem-modal-title {
    font-size: 40px;
    color: #8AB4F8;
  }

  .redeem-modal-balance {
    font-size: 25px;
    color: #3D9970;
  }

  .confirm-redeem-modal-balance {
    font-size: 25px;
    color: #8AB4F8;
  }

  .redeem-modal-success {
    font-size: 25px;
    color: #3D9970;
    text-align: center;
  }
.redeem-input {
  border-radius: 10px;
  height: 40px;
  font-size: 30px;
  margin-bottom: 10px;
  background-color: #0D2136;
  border-color: #4F647E;
  color: #D3E3FD;
}

.redeem-input::placeholder {
  font-size: 30px;
  color: #D3E3FD;
  text-align: center;
}

.redeem-numberPad {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  width: 100%;
  height: 60%;
  font-family: 'MathJax_Main';
  margin-bottom: 10px;
}
  
  .buttonText {
    font-size: 30px;
    font-weight: bold;
    color: #D3E3FD; /* White text color */
    font-family: 'MathJax_Main';
  }

  .redeem-buttons {
    background-color: #3D9970;
  padding: 5px;
  border-radius: 10px;
  color: #fff;
  font-size: 20px;
  width: 80%;
  margin-bottom: 20px;
  }

  .redeem-buttons:hover {
    background-color: #D3E3FD;
    color: #031525;
  }

  .redeem-buttons:active {
    background-color: #D3E3FD;
    color: #031525;
  }
  

  @media (max-width: 867px) {
    .brands-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Adjust minmax values as per your design */
      gap: 20px; /* Space between grid items */
      padding: 20px;
    }
    
    .brand {
      background-color: #0D2136 !important;
      box-shadow: 0 3px 2px #4F647E;
      /* border: 1px solid #ccc;  */
      padding: 15px;
      border-radius: 10px; /* Optional: for rounded corners */
      background-color: white; /* Optional: for background */
    }
  
  .brand-name {
    text-align: center;
    color: #D3E3FD;
    font-size: 18px;
  }
    
    .brand img {
      max-width: 100%; /* Ensures image does not overflow its container */
      height: auto;
    }
    
    .items {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Adjust for item cards */
      gap: 10px; /* Space between items */
      margin-top: 20px;
    }
    
    .item {
      color: #D3E3FD;
      /* border: 1px solid #ddd;  */
      padding: 2px;
      border-radius: 5px; /* Optional: for rounded corners */
      text-align: center; /* Optional: if you want text centered */
    }
    .button {
      width: 24%; /* Assuming 3 buttons per row */
      height: 35px;
      padding: 0px;
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .buttonText {
      font-size: 30px;
      font-weight: bold;
      
    }
  }

  @media (max-width: 768px) {
    .brands-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Adjust minmax values as per your design */
      gap: 20px; /* Space between grid items */
      padding: 20px;
    }
    
    .brand {
      background-color: #0D2136 !important;
      box-shadow: 0 3px 2px #4F647E;
      /* border: 1px solid #ccc;  */
      padding: 15px;
      border-radius: 10px; /* Optional: for rounded corners */
      background-color: white; /* Optional: for background */
    }
  
  .brand-name {
    text-align: center;
    color: #D3E3FD;
    font-size: 18px;
  }
    
    .brand img {
      max-width: 100%; /* Ensures image does not overflow its container */
      height: auto;
    }
    
    .items {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Adjust for item cards */
      gap: 10px; /* Space between items */
      margin-top: 20px;
    }
    
    .item {
      color: #D3E3FD;
      /* border: 1px solid #ddd;  */
      padding: 2px;
      border-radius: 5px; /* Optional: for rounded corners */
      text-align: center; /* Optional: if you want text centered */
    }
    .button {
      width: 23%; /* Assuming 3 buttons per row */
      height: 40px;
      padding: 0px;
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .buttonText {
      font-size: 23px;
      font-weight: bold;
     
    }
  }

  @media (max-width: 390px) {

    .brands-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Adjust minmax values as per your design */
      gap: 20px; /* Space between grid items */
      padding: 20px;
    }
    
    .brand {
      background-color: #0D2136 !important;
      box-shadow: 0 3px 2px #4F647E;
      /* border: 1px solid #ccc;  */
      padding: 15px;
      border-radius: 10px; /* Optional: for rounded corners */
      background-color: white; /* Optional: for background */
    }
  
  .brand-name {
    text-align: center;
    color: #D3E3FD;
    font-size: 18px;
  }
    
    .brand img {
      max-width: 100%; /* Ensures image does not overflow its container */
      height: auto;
    }
    
    .items {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Adjust for item cards */
      gap: 10px; /* Space between items */
      margin-top: 20px;
    }
    
    .item {
      color: #D3E3FD;
      /* border: 1px solid #ddd;  */
      padding: 2px;
      border-radius: 5px; /* Optional: for rounded corners */
      text-align: center; /* Optional: if you want text centered */
    }

    .button {
      width: 24%; /* Assuming 3 buttons per row */
      height: 35px;
      padding: 0px;
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .buttonText {
      font-size: 30px;
      font-weight: bold;
      
    }
  }
  
  