@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.profile-grade-stats {
  animation: slideInFromLeft 0.5s ease-out forwards;
  opacity: 0; /* Start with the element invisible to see the animation */
}


.profile-container {
  
  height: 100vh;
  background: #031525;

  }
  .profile-title{
    font-size: 40px;
    color: #3D9970;
    text-align: center;
  }
  .profile-top-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: #031525;
    /* border: 1px solid white; */
    padding: 10px;
  }
  .profile-bottom-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    background: #031525;
    /* border: 1px solid red; */
    margin: 0 auto; /* Centers the container */
    
  }
  .profile-highlights{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .profile-avatar{
    width: 200px;
    height: 200px;
    border: 2px solid #D3E3FD;
    border-radius: 100px;
    padding: 10px;
    margin-bottom: 10px;
  }
  .profile-username{
    color: #D3E3FD;
    font-size: 40px;
  }
  
  
  .main-stats-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #D3E3FD;
    background-color: #0D2136;
    /* border: 2px solid #3d9970; */
    border-radius: 20px;
    width: 60%;
    font-size: 15px;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .main-stats{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #D3E3FD;
    font-weight: bold;
    font-size: larger;
  }

  .main-stats-title {
    font-size: 30px;

  }

  .progress-text {
    font-size: 25px;
    fill: #D3E3FD;
  }

  .profile-difficulty-stats {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Adjust minmax for your preferred minimum and maximum box sizes */
    gap: 20px; /* Adjust the space between boxes */
    justify-content: center;
    align-items: start;
    width: 800px;
    height: 100%;
    padding: 20px;
  }
  
  .profile-grade-stats {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: #D3E3FD;
    background-color: #0D2136;
    /* border: 2px solid #3d9970; */
    border-radius: 15px; /* Adjust for desired border radius */
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Optional: Adds shadow for depth */
    font-size: 20px; /* Adjust as necessary */
  }

  .profile-grade-title{
    color: #D3E3FD;
  }

  .achievement-display {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px; /* Adjust as necessary */
  }
  
  .achievement-badge {
    
    width: 120px;
    height: 90px;
  }
  
  /* You can also adjust the main container if you want to center everything nicely */
  
  @media (max-width: 867px) {

    .main-stats-container {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      border-radius: 20px;
      width: 100%;
      font-size: 15px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .main-stats{
      
      font-weight: bold;
      font-size: larger;
      text-align: center;
    }
  
    .profile-difficulty-stats {
      
      width: 90%;
     
    }
    
    .profile-grade-stats {
      
      font-size: 25px; /* Adjust as necessary */
    }
  
    .profile-grade-title{
      font-size: 30px;
    
    }
  
    .achievement-display {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px; /* Adjust as necessary */
    }

    .achievement-badge {
      width: 90px; /* Adjust as necessary */
      height: 90px;
    }
    
    /* .achievement-badge {
      width: 80px; 
    height: 80px;
    } */
    
  }

  @media (max-width: 390px) {

    .main-stats-container {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      border-radius: 20px;
      width: 100%;
      font-size: 15px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .main-stats{
      
      font-weight: bold;
      font-size: larger;
      text-align: center;
    }
  
    .profile-difficulty-stats {
      
      width: 90%;
     
    }
    
    .profile-grade-stats {
      
      font-size: 25px; /* Adjust as necessary */
    }
  
    .profile-grade-title{
      font-size: 30px;
    
    }
  
    .achievement-display {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px; /* Adjust as necessary */
    }

    .achievement-badge {
      width: 90px; /* Adjust as necessary */
      height: 90px;
    }
    
    /* .achievement-badge {
      width: 80px; 
    height: 80px;
    } */
    
  }
  
  