/* FAQ.css */
.faq-container {
    padding: 20px;
    background-color: #4F647E;
    width: 80%;
    margin-top: 20px;
    border-radius: 25px;
  }
  
  .faq-header {
    font-size: 32px;
    font-weight: bold;
    color: #D3E3FD;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .faq-item {
    margin-bottom: 15px;
  }
  
  .faq-question {
    font-size: 30px;
    font-weight: bold;
    background: none;
    border: none;
    cursor: pointer;
    color: #D3E3FD;
    padding-top: 10px;
    border-top: 2px solid #031525;
    padding-bottom: 5px;
  }

  .faq-question:hover {
    background-color: #0D2136;
    color: #D3E3FD;
  }
  
  .faq-answer {
    color: #D3E3FD;
    font-size: 20px;
    margin-top: 10px;
  }




  @media (max-width: 768px) { /* Example for tablets and smaller devices */
    
    .faq-container {
      padding: 20px;
      width: 95%;
    }
    .faq-question {
      font-size: 20px; /* Smaller font size for titles */
    }
  
    .faq-answer {
      font-size: 15px; /* Smaller font size for labels */
    }
  
  }
  