/* EarningsDisplay.css */

/* EarningsDisplay.css */
@keyframes pulse {
  0% {
    transform: scale(1);
    color: #3D9970;
  }
  20% {
    transform: scale(1.5);
    color: #D3E3FD;
  }
  40% {
    transform: scale(1); 
    color: #3D9970;
  }
  60% {
    transform: scale(1.5); 
    color: #D3E3FD;
  }
  80% {
    transform: scale(1.5); 
    color: #3D9970;
  }
  100% {
    transform: scale(1); 
    color: #D3E3FD;
  }
}

.pulsing {
  animation: pulse 1s infinite; /* Apply the pulse animation */
}


.balanceContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 5px;
    background-color: #162C46;
    border: 2px solid #4F647E;
    border-radius: 5px;
    
    
  }
  
  .balanceText {
    color: #D3E3FD;
    font-size: 17px;
    font-weight: bold;
    margin: 0px;
  }

  


  

@media (max-width: 667px) {
  .balanceContainer {
    
  }
  
  .balanceText {
    font-size: 14px;
    font-weight: bold;
    margin: 0px;
  }
}

@media (max-width: 448px) {
  .balanceContainer {
    
  }
  
  .balanceText {
    font-size: 18px;
    font-weight: bold;
    margin: 0px;
  }
}

@media (max-width: 390px) {
  .balanceContainer {
    
  }
  
  .balanceText {
    font-size: 14px;
    font-weight: bold;
    margin: 0px;
  }
}