@keyframes cascadeRightToLeft {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.temp-div {
  display: flex;
  flex-direction: row;
}

.temp-button-title {
font-size: 25px;
}

.temp-title {
  font-size: 40px;
  text-align: center;
}

.temp-info-blurb {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  overflow: auto;
  position: fixed; /* or absolute, depending on your needs */
  z-index: 1000; /* Ensures it's above other content */
  top: 52%; /* Adjust these to control the blurb's position */
  left: 50%;
  transform: translate(-50%, -50%); /* Center the blurb */
  background-color: white; /* Choose your desired background */
  padding: 20px; /* Add some padding around the content */
  border: 1px solid #ccc; /* Optional border */
  border-radius: 5px; /* Optional rounded corners */
  width: 700px;
  height: 750px;
  display: flex;
  flex-direction: column;
}

.temp-list {
  color: black;
  font-size: 20px;
}

.temp-list-special {
  color: #3D9970;
  font-size: 20px;
  font-weight: bold;
}

.temp-button-container {
  display: flex;
  justify-content: center;
  width: 90%;
  margin-bottom: 10px;
}

.temp-blurb-button {
  width: 100%;
}

.temp-button {
  margin-right: 20px;
  position: relative; /* Allows precise positioning */
    z-index: 1;
    border-radius: 20px;
    width: 250px; /* Adjust based on your design */
    height: 50px; /* Adjust based on your design */
    background-color: #0D2136; /* Example button color, adjust as needed */
    color: white; /* Text color, adjust as needed */
    /* clip-path: circle(50%); Creates a circular shape */
    border: 2px solid red;
   
    cursor: pointer; /* Changes the cursor to a pointer to indicate it's clickable */
   
}

.menu-item {
  display: block;
  opacity: 0; /* Start items as invisible */
  animation: cascadeRightToLeft 0.3s ease-out forwards;
}

/* Use the :nth-child selector to delay each item's animation */
.menu-item:nth-child(1) { animation-delay: 0.1s; }
.menu-item:nth-child(2) { animation-delay: 0.2s; }
.menu-item:nth-child(3) { animation-delay: 0.3s; }
.menu-item:nth-child(4) { animation-delay: 0.4s; }
.menu-item:nth-child(5) { animation-delay: 0.5s; }
.menu-item:nth-child(6) { animation-delay: 0.6s; }
.menu-item:nth-child(7) { animation-delay: 0.7s; }
/* Continue as needed for additional items */



header {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Align items vertically in the header */
  width: 100%;
  height: 7vh; /* Header height */
  padding: 10px;
  /* background-color: #ebf3f0; */
  background-color: #031525;
  /* position: fixed; Fix the header's position
  top: 0; Align the top edge of the header with the top of the viewport
  left: 0; Align the left edge of the header with the left of the viewport
  right: 0; Ensure the header extends to the right edge of the viewport
  z-index: 1000; Ensure the header is above other content
  Add more styles for your header here (box-shadow, height, etc.) */
}

.header-left {
  display: flex;
  align-items: center; /* Align items vertically */
}

.logo-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex; /* Use flex to center the image within the button */
  align-items: center;
}

.logo-image {
  height: 3vh; /* 10% of the viewport height */
  width: auto; /* Maintain aspect ratio */
  object-fit: contain; /* Ensure the image is scaled correctly */
}


.header-right {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}




.header-button {
  background-color: #3D9970;
  padding: 5px;
  border-radius: 10px;
  color: #fff;
  font-size: 20px;
}

/* #dropdown-basic {
  height: auto;
  background-color: #031525;
  border: none !important;
}

.dropdown-menu.show {
  color: #D3E3FD; 
  background-color: #031525; /
}

.dropdown-item {
  color: #D3E3FD !important; 
  background-color: #031525; 
}


.dropdown-item:hover,
.dropdown-item:focus {
  background-color: #D3E3FD !important; 
  color: #031525 !important; 
}

.dropdown-item.active {
  background-color: #333; 
  color: #fff; 
}

.dropdown-divider {
  border-top: 1px solid #D3E3FD !important; 
  background-color: #031525; 
} */



/* Header.css */


.faHouse, .faGear, .faQuestion, 
.faHeadset, .faRightFromBracket {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

/* Styles for the react-burger-menu component */
.menu-header {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  padding: 20px; /* Adjust padding as needed */
  border-bottom: 1px solid #ccc; /* Optional: adds a separator line */
}


.header-avatar {
  width: 100px;
  height: 100px;
  /* margin-right: 10px; */
}

.header-username {
  color: #3D9970;
}



.bm-burger-button {
  position: relative;
  width: 36px; /* Width of button */
  height: 30px; /* Height of button */
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  border-radius: 60px;
  background: #D3E3FD;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  overflow: hidden;
  background: #031525;
  padding: 2.5em 1.5em 0;
  font-size: 2.15em;
}




/* Styling of overlay */
.bm-overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-item {
  text-decoration: none;
  color: #D3E3FD; /* Color of menu items */
  padding: 10px 0;
  display: block;
  transition: color 0.2s;
}

.menu-item:hover {
  color: #8AB4F8; /* Color of menu items on hover */
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47; /* Initial color */
  /* animation: colorPulse 2s ease-in-out infinite; */
}

/* @keyframes menuPulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

.bm-burger-bars {
  animation: pulse 1s infinite ease-in-out;
} */


/* @keyframes colorPulse {
  0% {
    fill: #373a47; Original color
  }
  50% {
    fill: #D3E3FD; New color
  }
  100% {
    fill: #373a47; Back to original color
  }
} */

@media (max-width: 448px) {
  .temp-div {
    display: flex;
    flex-direction: row;
  }
  
  .temp-button-title {
  font-size: 18px;
  }
  
  .temp-title {
    font-size: 20px;
    text-align: center;
  }
  
  .temp-info-blurb {
    overflow: auto;
    position: fixed; /* or absolute, depending on your needs */
   
    background-color: white; /* Choose your desired background */
    padding: 20px; /* Add some padding around the content */
    border: 1px solid #ccc; /* Optional border */
    border-radius: 5px; /* Optional rounded corners */
    width: 370px;
    height: 620px;
  }
  
  .temp-list {
    color: black;
    font-size: 14px;
  }
  
  .temp-button {
    margin-right: 30px;
    position: relative; /* Allows precise positioning */
      z-index: 1;
      border-radius: 20px;
      width: 150px; /* Adjust based on your design */
      height: 35px; /* Adjust based on your design */
      background-color: #0D2136; /* Example button color, adjust as needed */
      color: white; /* Text color, adjust as needed */
      /* clip-path: circle(50%); Creates a circular shape */
      border: 2px solid red;
     
      cursor: pointer; /* Changes the cursor to a pointer to indicate it's clickable */
     
  }
}

@media (max-width: 390px) {
  .temp-div {
    display: flex;
    flex-direction: row;
  }
  
  .temp-button-title {
  font-size: 18px;
  }
  
  .temp-title {
    font-size: 20px;
    text-align: center;
  }
  
  .temp-info-blurb {
    
    width: 370px;
    height: 620px;
  }
  
  .temp-list {
    color: black;
    font-size: 14px;
  }
  
  .temp-button {
    margin-right: 30px;
    
  }
}