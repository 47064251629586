.account-update-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
  align-content: center;
  width: 100%; 
  height: 100%;
  }
  
  .case-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px;
    /* border: 1px solid #000;  */
    
  }

  .change-form-inputs{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
  }

  .change-form-inputs-avatar{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
  }

  .change-title {
    font-size: 30px;
    margin-bottom: 20px;
    color: #3d9970;
    text-align: center;
  }
  
  .change-input {
    font-size: 20px;
    text-align: 'center';
    background-color: transparent; /* Make the background transparent */
    border: 1px solid #000; /* Example border: black and 1px wide */
    margin: 5px;
    color: black; /* Text color */
    padding: 10px;
    width: 100%; /* Padding inside the input */
     /* Font size */
    border-radius: 5px; /* Rounded corners for the border */
    outline: none;
  }
  
  .change-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;

  }

  .submit-button {
    background-color: #233565;
    padding: 5px;
    border-radius: 10px;
    color: #fff;
    font-size: 20px;
    width: 100%;
    margin-top: 10px;
  }
  .submit-button-avatar {
    background-color: #233565;
    padding: 5px;
    border-radius: 10px;
    color: #fff;
    font-size: 20px;
    width: 40%;
    margin-top: 10px;
  }


  .forgot-link {
    margin-left: 10px;
  }


  
  @media (max-width: 867px) {

    .change-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 95%;
  
    }
    .change-form-inputs{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 95%;
    }
    
    .change-form-inputs-avatar {
      width: 100%;
    }
    
  }
  
  @media (max-width: 768px) {}