.change-avatar-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    
  }
  
  .generate-button {
    background-color: #3d9970;
    padding: 5px;
    border-radius: 10px;
    color: #fff;
    font-size: 20px;
    width: 30%;
    margin-top: 10px;
  }

.change-avatar-generated {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    border: 1px solid #3d9970;
    border-radius: 20px;
    /* padding: 5px; */
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 15px;
    width: 80%;
  }
  
  .change-avatar-option {
    border: none;
    cursor: pointer;
    background: none;
    margin: 5px;
    
    width: auto; /* Adjust size as needed */
    height: auto;
    border-radius: 45px;
    transition: transform 0.2s;
  }
  
  .change-avatar-option:hover {
    transform: scale(1.2);
    
  }

  .selected-avatar {
    
    border: 1px solid #3d9970; /* Highlight color */
    transform: scale(1.2); /* Slightly enlarge the selected avatar */
  }
  
  .change-avatar-image {
    width: 100px; /* Adjust size as needed */
    height: 100px;
    border-radius: 5px;
  }
  

  @media (max-width: 867px) {
    .generate-button {
        
        width: 40%;
        
      }
    
    .change-avatar-generated {
        
        width: 100%;
      }
      
      .change-form-inputs-avatar {
        width: 100%;
      }
      
      .change-avatar-image {
        width: 90px; /* Adjust size as needed */
        height: 90px;
        border-radius: 5px;
      }
      

  }