.support-container {
    width: 100%;
    height: 100vh;
    background-color: #031525;
  }

  .support-form-container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    height: 100vh;
    background-color: #f1faee;
    
  }
  
  .support-header {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    color: #709775;
    margin-bottom: 20px;
  }
  
  .support-description {
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    color: #709775;
    margin-bottom: 20px;
  }
  
  .support-field-label {
    color: #709775;
    display: block;
    margin-bottom: 10px;
  }
  
  .support-input-subject,
  .support-input-description {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid gray;
    border-radius: 5px;
  }
  
  .support-input-description {
    min-height: 100px;
    max-height: 200px;
  }
  
  .support-button {
    background-color: #709775;
    color: white;
    padding: 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: block;
    width: 60%;
    margin: auto;
    font-size: 18px;
  }
  