@keyframes fallDownProfileRewards {
  0% {
    transform: translateY(-100vh); /* Start above the screen */
    opacity: 0;
  }
  100% {
    transform: translateY(-140%); /* End at the final position for profile and rewards buttons */
    opacity: 1;
  }
}

@keyframes fallDownInsightsLeaderboard {
  0% {
    transform: translateY(-100vh); /* Start above the screen */
    opacity: 0;
  }
  100% {
    transform: translateY(-60%); /* End at the final position for insights and leaderboard buttons */
    opacity: 1;
  }
}

@keyframes fallDownPlay {
  0% {
    transform: translateY(-100vh); /* Start above the screen */
    opacity: 0;
  }
  100% {
    transform: translateY(0); /* No additional translateY for play button */
    opacity: 1;
  }
}


.profileButton, .rewardsButton {
  animation: fallDownProfileRewards 0.6s ease-out forwards;
}

.insightsButton, .leaderboardButton {
  animation: fallDownInsightsLeaderboard 0.4s ease-out forwards;
}

.playButton {
  animation: fallDownPlay 0.8s ease-out forwards;
}



/* Example delays for each button */
/* .menuButton:nth-child(1) { animation-delay: 0s; }
.menuButton:nth-child(2) { animation-delay: 0.2s; }
.menuButton:nth-child(3) { animation-delay: 0.4s; }
.menuButton:nth-child(4) { animation-delay: 0.6s; }
.menuButton:nth-child(5) { animation-delay: 0.8s; } */
/* Add more as needed for additional buttons */



.menu-container {
    display: flex;
    flex-direction: column;
    
    width: 100%;
    height: 100vh;
    background-color: #031525;
}

.topContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    /* height: 100%; */
    background-color: #031525;
}

.info-badge-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  .info-badge {
    background-color: #ffcc00; /* Yellow background color */
    color: black; /* Black text color */
    padding: 8px;
    border-radius: 50%; /* Circular badge */
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 14px;
    width: 24px; /* Width of the badge */
    height: 24px; /* Height of the badge */
    cursor: pointer;
  }
  
  .info-blurb {
    position: absolute;
  top: 120%; /* positions below the badge */
  left: 60%;
  transform: translateX(-50%); /* center aligns the tooltip */
  width: 200px; /* adjust as needed */
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  z-index: 2; /* ensure it's above other elements */
  }

.menu-spikes {
    --spike-color: #0D2136;
    --spike-width: 100%;
    --spike-height: 150px;
    position: relative;
    background: linear-gradient(
      to right,
      #d2e2f0,
      #3896d4
    );
    
   /* padding-block: 40px; */
   
  }
  
  .menu-spikes::before, .menu-spikes::after {
    content: '';
    position: absolute;
    width: 100%;
    height: var(--spike-height);
    background: var(--spike-color);
    -webkit-mask-image :url('../../assets/cloud.svg');
    -webkit-mask-size: var(--spike-width) var(--spike-height);
    
    /* -webkit-mask-repeat: no-repeat; */
  }
  
  .menu-spikes::before{
    top: 0;
    
  }
  
  .menu-spikes::after{
  bottom: 0;
  transform: rotate(.5turn);
  }

  .top-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }

  .menu-avatar{
    z-index: 1;
    background-color: #031525;
    width: 200px;
    height: 200px;
    border: 2px solid #D3E3FD;
    border-radius: 100px;
    padding: 10px;
    margin-bottom: 10px;
    margin-top: 50px;
  }

  .menu-welcome-title {
    border-radius: 30px;
    background-color: #031525;
    padding: 15px;
    color: #D3E3FD;
    font-weight: bold;
    height: 80px;
  }



.menu-title {
    color: #233565;
    font-size: 60px;
    font-weight: bold;
}

.button-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.button-title {
    font-size: 30px;
}

.faPlay, .faGift, .faUser, 
.faLightbulb, .faMedal, .faTrophy {
    width: 60px;
    height: 60px;
}

/* .bottomContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    flex: 1;
    background-color: #031525;
}

.menuButtonContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
} */
.bottomContainer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    /* height: 100%; */
  }
  
  .menuButtonContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }

  
.menuButton {
    border-radius: 10px;
    width: 250px;
    height: 150px;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    margin: 10px;
    color: #D3E3FD;
    font-size: 15px;
    border: none;
    cursor: pointer;
    box-shadow: 0 14px 10px #4F647E;
    background-color: #0D2136; /* Default background color for buttons */
}

.circleButton {
    position: relative; /* Allows precise positioning */
    z-index: 1;
    border-radius: 200px;
    width: 200px; /* Adjust based on your design */
    height: 200px; /* Adjust based on your design */
    background-color: #0D2136; /* Example button color, adjust as needed */
    color: white; /* Text color, adjust as needed */
    /* clip-path: circle(50%); Creates a circular shape */
    border: none; /* Removes the border */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; /* Changes the cursor to a pointer to indicate it's clickable */
    filter: drop-shadow(0 15px 4px rgba(92, 91, 91, 0.5));
}

  
  
  /* Add :hover and :focus styles for better UX */
  .circleButton:hover, .circleButton:focus {
    background-color: #0056b3; /* Darker shade for hover state, adjust as needed */
  }

.menuButton:hover {
    color: #233565;
    background-color: #D3E3FD; /* Default background color for buttons */
}

.menuButton:active {
    color: #233565;
    background-color: #D3E3FD;
}

.circleButton:hover {
    color: #233565;
    background-color: #D3E3FD; /* Default background color for buttons */
}

.circleButton:active {
    color: #233565;
    background-color: #D3E3FD;
}

.playButton {
    background-color: #3D9970;
    margin-top: 60px;
}

.profileButton {
  color: #8e7dbe;
    transform: translateY(-140%); /* Adjust the percentage to create the curve */
  }
  
  .insightsButton {
    color: #f07167;
    transform: translateY(-60%); /* Slightly less transformation to maintain the curve */
  }
  
  .leaderboardButton {
    color: #e9c46a;
    transform: translateY(-60%); /* Same as insightsButton for symmetry */
  }
  
  .rewardsButton {
    color: #80ed99;
    transform: translateY(-140%); /* Same as profileButton for symmetry */
  }


@media (max-width: 867px) {
.top-button-container{
    
}
.menu-avatar{
    
    width: 150px;
    height: 150px;
    padding: 10px;
    margin-bottom: 10px;
    margin-top: 50px;
  }
    .menu-spikes {
        
        --spike-width: 100%;
        --spike-height: 215px;
        position: relative;
        background: linear-gradient(
          to right,
          #d2e2f0,
          #3896d4
        );
        
       /* padding-block: 40px; */
       
      }
    .menu-title {
        font-size: 25px;
    }

    .button-title {
        font-size: 15px;
    }

    .menuButtonContainer {
        
        flex-wrap: nowrap;
      }

    .menuButton {
        width: 140px;
        height: 100px;  
    }

    

    .circleButton {
        border-radius: 100px;
        width: 100px;
        height: 100px;
       
    }

    .profileButton {
        transform: translateY(-260%); /* Adjust the percentage to create the curve */
      }
      
      .insightsButton {
        transform: translateY(-200%); /* Slightly less transformation to maintain the curve */
      }
      
      .leaderboardButton {
        transform: translateY(-200%); /* Same as insightsButton for symmetry */
      }
      
      .rewardsButton {
        transform: translateY(-260%); /* Same as profileButton for symmetry */
      }


    .faPlay, .faGift, .faUser, 
.faLightbulb, .faMedal, .faTrophy {
    width: 60px;
    height: 30px;
}

@keyframes fallDownProfileRewards {
  0% {
    transform: translateY(-100vh); /* Start above the screen */
    opacity: 0;
  }
  100% {
    transform: translateY(-260%); /* End at the final position for profile and rewards buttons */
    opacity: 1;
  }
}

@keyframes fallDownInsightsLeaderboard {
  0% {
    transform: translateY(-100vh); /* Start above the screen */
    opacity: 0;
  }
  100% {
    transform: translateY(-200%); /* End at the final position for insights and leaderboard buttons */
    opacity: 1;
  }
}

@keyframes fallDownPlay {
  0% {
    transform: translateY(-100vh); /* Start above the screen */
    opacity: 0;
  }
  100% {
    transform: translateY(0); /* No additional translateY for play button */
    opacity: 1;
  }
}

}

@media (max-width: 768px) {
    .menu-spikes {
        
        --spike-width: 100%;
        --spike-height: 120px;
        position: relative;
        background: linear-gradient(
          to right,
          #d2e2f0,
          #3896d4
        );
        
       /* padding-block: 40px; */
       
      }

    .menu-title {
        font-size: 25px;
    }
    .menuButton {
        width: 120px;
        height: 80px;  
    }

    .profileButton {
      transform: translateY(-260%); /* Adjust the percentage to create the curve */
    }
    
    .insightsButton {
      transform: translateY(-200%); /* Slightly less transformation to maintain the curve */
    }
    
    .leaderboardButton {
      transform: translateY(-200%); /* Same as insightsButton for symmetry */
    }
    
    .rewardsButton {
      transform: translateY(-260%); /* Same as profileButton for symmetry */
    }

    
    .faPlay, .faGift, .faUser, 
.faLightbulb, .faMedal, .faTrophy {
    width: 60px;
    height: 30px;
}

}

@media (max-width: 390px) {

  .menu-container {
    display: flex;
    flex-direction: column;
    
    width: 100%;
    height: 100vh;
    background-color: #031525;
}
  .top-button-container{
      
  }
  .menu-avatar{
      
      width: 25vw;
      height: 15vh;
      padding: 10px;
      margin-bottom: 10px;
      margin-top: 50px;
    }
      .menu-spikes {
          
          --spike-width: 100%;
          --spike-height: 15vh;
          position: relative;
          background: linear-gradient(
            to right,
            #d2e2f0,
            #3896d4
          );
          
         /* padding-block: 40px; */
         
        }
      .menu-title {
          font-size: 25px;
      }

      .menu-welcome-title {
        font-size: 20px;
      }
  
      .button-title {
          font-size: 13px;
      }
  
      .menuButtonContainer {
          
          flex-wrap: nowrap;
        }
  
      .menuButton {
          width: 15vh;
          height: 10vh;  
      }
  
      
  
      .circleButton {
          border-radius: 100px;
          width: 95px;
          height: 95px;
          
         
      }
  
      .profileButton {
          transform: translateY(-260vh); /* Adjust the percentage to create the curve */
        }
        
        .insightsButton {
          transform: translateY(-200vh); /* Slightly less transformation to maintain the curve */
        }
        
        .leaderboardButton {
          transform: translateY(-200vh); /* Same as insightsButton for symmetry */
        }
        
        .rewardsButton {
          transform: translateY(-260vh); /* Same as profileButton for symmetry */
        }
  
  
      .faPlay, .faGift, .faUser, 
  .faLightbulb, .faMedal, .faTrophy {
      width: 60px;
      height: 30px;
  }
  
  @keyframes fallDownProfileRewards {
    0% {
      transform: translateY(-100vh); /* Start above the screen */
      opacity: 0;
    }
    100% {
      transform: translateY(-20vh); /* End at the final position for profile and rewards buttons */
      opacity: 1;
    }
  }
  
  @keyframes fallDownInsightsLeaderboard {
    0% {
      transform: translateY(-100vh); /* Start above the screen */
      opacity: 0;
    }
    100% {
      transform: translateY(-10vh); /* End at the final position for insights and leaderboard buttons */
      opacity: 1;
    }
  }
  
  @keyframes fallDownPlay {
    0% {
      transform: translateY(-100vh); /* Start above the screen */
      opacity: 0;
    }
    100% {
      transform: translateY(0); /* No additional translateY for play button */
      opacity: 1;
    }
  }
  
  }