/* NumberPadButton.css */

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%; /* Assuming 3 buttons per row */
    /* aspect-ratio: 2.4;  */
    height: 40px;
    padding: 0;
    border-radius: 10px; /* Round the corners */
    /* overflow: hidden;  */
    margin: 3px; /* Space between buttons */
    border: 2px solid #4F647E;
    background: none;
    cursor: pointer;
    box-shadow: 0 2px 2px #D3E3FD;
    background: linear-gradient(to right, #0D2136, #0D2136);
    opacity: 0; /* Make buttons fully transparent initially */
  transform: translateY(-100vh); /* Start above the screen */
  animation: dropButton 0.3s ease-out forwards;
  }

  .button:hover {
    background: linear-gradient(to right, #8AB4F8, #8AB4F8); 
  }
  
  .button:active {
    transform: scale(1.1) !important;
  }
  
  .button:nth-child(1) { animation-delay: 0.1s; }
  .button:nth-child(2) { animation-delay: 0.2s; }
  .button:nth-child(3) { animation-delay: 0.3s; }
  .button:nth-child(4) { animation-delay: 0.4s; }
  .button:nth-child(5) { animation-delay: 0.5s; }
  .button:nth-child(6) { animation-delay: 0.6s; }
  .button:nth-child(7) { animation-delay: 0.7s; }
  .button:nth-child(8) { animation-delay: 0.8s; }
  .button:nth-child(9) { animation-delay: 0.9s; }
  .button:nth-child(10) { animation-delay: 1s; } /* Adjust for "+/-" */
  .button:nth-child(11) { animation-delay: 1.1s; } /* Adjust for "0" */
  .button:nth-child(12) { animation-delay: 1.2s; } /* Adjust for "." */
  .button:nth-child(13) { animation-delay: 1.3s; } /* Adjust for "Del" */
  .button:nth-child(14) { animation-delay: 1.4s; } /* Adjust for "C" */
  
  
  .buttonText {
    font-size: 30px;
    font-weight: bold;
    color: #D3E3FD; /* White text color */
    font-family: 'MathJax_Main';
  }

  
  
  /* @media (max-width: 768px) {
    .button {
      width: 23%; 
      height: 40px;
      padding: 0px;
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .buttonText {
      font-size: 23px;
      font-weight: bold;
     
    }
  } */

  
  @media (max-width: 667px) {
    .button {
      width: 85px; /* Assuming 3 buttons per row */
      height: 25px;
      padding: 0px;
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .buttonText {
      font-size: 23px;
      font-weight: bold;
      
    }
  }
  

  @media (max-width: 448px) {
    .button {
      width: 24%; /* Assuming 3 buttons per row */
      height: 30px;
      padding: 0px;
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .buttonText {
      font-size: 30px;
      font-weight: bold;
      
    }
  }

 

  @media (max-width: 390px) {
    .button {
      width: 90px; /* Assuming 3 buttons per row */
      height: 4vh;
      padding: 0px;
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .buttonText {
      font-size: 23px;
      font-weight: bold;
      
    }
  }
  