.subscription-screen-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh; /* 100% of the viewport height */
  background-color: #031525;

}

.subscription-button-container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 10px;
}

.subscription-button-container button:disabled {
  font-weight: bold;
}


/* .subscription-button {
  background-color: white;
  width: 60%;
  border: 2px solid;
  border-color: #3d9970;
} */

.subscription-button-free {
  background-color: #0D2136;
  width: 60%;
  border: 2px solid;
  border-color: #3d9970;
}
.subscription-button-casual {
  background-color: #0D2136;
  width: 60%;
  border: 2px solid;
  border-color: #233565;
}
.subscription-button-max {
  background-color: #D3E3FD;
  width: 60%;
  border: 2px solid;
  border-color: #df5959;
}

.subscription-submit-button {
  background-color: #3D9970;
  color: #D3E3FD;
  font-weight: bold;
  font-size: 30px;
  width: 30%;
  border: 2px solid;
  /* border-color: white; */
  border-radius: 20px;
  
}

.subscription-submit-button:hover {
  background-color: #D3E3FD;
  color: black;
  transform: scale(1.05);
  border: 2px solid;
  border-color: black;
  border-radius: 20px;
  
}

.selected-plan {
  transform: scale(1.1);
}

.selected-button {
  color: #D3E3FD;
}

.current-plan {
  transform: scale(1.1);
}

.current-button {
  color: #D3E3FD;
}


.subscription-info-container {
  display: flex;
  flex-direction: column;
  height: 525px;
  width: 350px;
  /* align-items: center; */
  z-index: 1;
  color: #D3E3FD;
  background-color: #0D2136;
  /* border: 1px solid #3d9970; */
  border-radius: 40px;
  box-shadow: 0 10px 6px #4F647E;
  /* padding: 10px; */
  margin: 20px;
}

.subscription-info-title-free {
  color: #D3E3FD;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  background-color: #3d9970;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;

}
.subscription-info-title-casual {
  color: #D3E3FD;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  background-color: #233565;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;

}
.subscription-info-title-max {
  color: #D3E3FD;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  background: linear-gradient(
    to right,
    #df5959,
    #9a22b3
  );
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;

}

.subscription-info-label1 {
  font-size: 20px;
  margin-left: 15px;
  margin-right: 15px
}

.subscription-info-label2 {
  font-size: 20px;
  margin-left: 15px;
  margin-right: 15px;
  /* text-align: center; */
}

.subscription-info-label3 {
  font-size: 18px;
  margin-left: 15px;
  margin-right: 15px;
  text-align: center;
}

.subscription-info-price {
  font-size: 25px;
  font-weight: bold;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
}

.subscription-settings-link {
  text-align: center;
}

.green-checkmark {
  color: #00B69F;
  margin-right: 10px;
}
.red-checkmark {
  color: #D11D5E;
  margin-right: 10px;
}

.green-price {
  font-size: 20px;
  font-weight: bold;
  color: #00B69F;
  margin-left: 10px;
}

form {
    
}

.my-input {
    padding: 10px;
    border: 1px solid #ccc;
  }





  @media (max-width: 768px) { /* Example for tablets and smaller devices */
   
  .welcome-subscription {
  display: flex;
  flex-direction: column;
  }
  
    .welcome-subscription-info-container {
      
      margin: 20px;
    }
    
    .welcome-subscription-info-title1 {
      font-size: 30px;
    }
    
    .welcome-subscription-info-label1 {
      font-size: 20px;
    }
    .welcome-subscription-info-label3 {
      font-size: 15px;
    }
  }