.loadingScreen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full height */
    width: 100vw; /* Full width */
    background-color: #031525;
  }
  
  .loading-image {
    width: 300px;
    height: 50px;
  }

  .logo {
    margin-bottom: 20px;
  }
  
  .spinningBar {
    width: 200px;
    height: 200px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3d9970;
    border-radius: 50%;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  