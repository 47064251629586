.insights-spikes {
  --spike-color: #0D2136;
  --spike-width: 100%;
  --spike-height: 80px;
  position: relative;
  /* background-color: #233565; */
  background: linear-gradient(
    to right,
    #031525,
    #031525
  );

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
 padding-block: 40px;
 width: 100%;
 
}

.insights-spikes::before, .insights-spikes::after {
  
  content: '';
  position: absolute;
  width: 100%;
  height: var(--spike-height);
  background: var(--spike-color);
  -webkit-mask-image :url('../../assets/trapezoid.svg');
  -webkit-mask-size: var(--spike-width) var(--spike-height);
  
  /* -webkit-mask-repeat: no-repeat; */
}

.insights-spikes::before{
  top: 0
}

.insights-spikes::after{
bottom: 0;
transform: rotate(.5turn);
}

.insights-spikes2 {
  --spike-color: #0D2136;
  --spike-width: 100%;
  --spike-height: 80px;
  position: relative;
  
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  padding-block: 40px;
  width: 100%;
}

.insights-spikes2::before, .insights-spikes2::after {
  content: '';
  position: absolute;
  width: 100%;
  height: var(--spike-height);
  background: var(--spike-color);
  -webkit-mask-image :url('../../assets/trapezoid.svg');
  -webkit-mask-size: var(--spike-width) var(--spike-height);
  /* -webkit-mask-repeat: no-repeat; */
}

.insights-spikes2::before{
  top: 0
}

.insights-spikes2::after{
bottom: 0;
transform: rotate(.5turn);
}

.insights-container {
    background-color: #cbd2e4;
    height: 100vh;
  }

  .insights-selector {
    color: #D3E3FD;
    margin-right: 10px;
    
  }

  .grade-selector-container {
    padding: 10px;
    /* border: 1px solid #4F647E; */
    border-radius: 10px;
    
  }
  
  .grade-selector{
    align-items: center;
    font-size: 30px;
  border-radius: 10px;
  text-align: center;
  color: #D3E3FD;
  background: #0D2136;
  margin-top: 20px;
  margin-bottom: 20px;
  border-color: #4F647E;
  }
  
.overall-performance-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #cbd2e4;
  /* border: 1px solid white; */
  padding-top: 80px;
  padding-bottom: 80px;
}

.insights-border-top {
  display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #0D2136;
    
  width: 50%;
  padding: 10px;
  border: 2px solid #4F647E;
  box-shadow: 0px 10px 20px #4F647E;
}
.insights-border-bottom {
  display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #0D2136;
    
  width: 50%;
  padding: 10px;
  margin-top: 40px;
  margin-bottom: 40px;
  border: 2px solid #4F647E;
  box-shadow: 0px 15px 40px #4F647E;
}

.insights-title {
  text-align: center;
  color: #3D9970 
}

.insights-strengths-title, .insights-weaknesses-title {
  text-align: center;
  color: #D3E3FD 
}


.insights-note {
  font-size: 16px;
  text-align: center;
  color: #D3E3FD;
  margin-top: 5px;
  margin-bottom: 5px;
}
.strengths-weaknesses {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 80%;
  margin-top: 20px;
}

.strengths {
  padding: 10px;
  border-radius: 10px;
  /* background-color: #0d3623; */
  border: 1px solid #00B69F;
  margin-right: 10px;

}

.weaknesses {
  padding: 10px;
  border-radius: 10px;
  /* background-color: #360d0d; */
  border: 1px solid #D11D5E;
}

li, ul {
  font-size: 20px;
  color: #D3E3FD
}

.grade-performance-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
    to right,
    #031525,
    #031525
  );
  /* padding-top: 10px; */
  /* padding-bottom: 10px; */
  border: 1px solid #4F647E;
}
  .pickerContainer {
    margin-bottom: 20px;
  }
  
  .picker {
    height: 30px;
    width: 100%;
    background-color: #ccc;
    border-color: #fff;
    padding-left: 10px;
    margin-bottom: 20px;
    color: #709775;
  }

  .grade-specific-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 10px;
    /* padding-bottom: 80px; */
    
  }

  .progress-bar-container {
    /* width: 40%; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* text-align: center; */
  }

  .stats-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    color: #D3E3FD;
    /* border: 1px solid #3d9970; */
    border-radius: 20px;
    width: 40%;
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px;
    text-align: center;

    background: linear-gradient(
    to right,
    #34834e,
    #4f63be
  );
  }
  
  .loader {
    text-align: center;
    padding: 50px;
    font-size: 24px;
  }

  .favorite-category {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .pie-chart-container {
    width: 100%;
    /* height: 60%; */
    border-top: 1px solid #4F647E;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  .bar-chart-container {
    
    /* height: 100%;  */
    width: 100%;
    border-top: 1px solid #4F647E;
    padding-top: 10px;
    padding-bottom: 40px;
  }
  
  

  @media (max-width: 867px) {

    .insights-border-top {
      
      width: 90%;
      padding: 10px;
      
    }
    .insights-border-bottom {
      width: 90%;
      padding: 10px; 
      
    }

    .grade-specific-details {
      padding: 10px;
    }

    
    .strengths-weaknesses {
      width: 100%;
    }

    .stats-container {
      width: 100%;
    }

    .progress-bar-container {
      width: 90%;
    }

    .pie-chart-container {
      height: 100%; 
      width: 100%;
      
    }
    .bar-chart-container {
     
      height: 100%; 
      width: 100%;
      
    }
    
    
  
  }