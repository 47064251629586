.settings-container {
    background-color: #031525;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }

  .settings-avatar {
    width: 40px;
    height: 40px;

  }
  
  .settings-titleContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
  
  .settings-title {
    font-size: 40px;
    font-weight: bold;
    color: #3D9970;
    margin-bottom: 20px;
  }
  
  .category-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-top: 3px solid #4F647E;
    width: 60%;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .category-title {
    color: #D3E3FD;
    width: auto;
    font-size: 25px;
    margin-bottom: 20px;
  }

  .settings-info-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    
    width: 100%;
    margin-right: 10px;
    
  }
  
  .settings-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 10px;
    padding-bottom: 10px;
    font-size: 18px;
    color: #D3E3FD;
    border-bottom: 1px solid #4F647E;
  }
  
  .settings-link {
    background-color: #3D9970;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 5px;
    font-size: 15px;
    border: none;
    cursor: pointer;
    text-align: center;
  }

.settings-link:hover {
  background-color: #D3E3FD;
  color: #031525;
}

  .settings-link:active {
    background-color: #D3E3FD;
    color: #3D9970;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 5px;
    font-size: 15px;
    border: none;
    cursor: pointer;
    text-align: center;
  }

  .settings-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
  }

  /* ////////////////////////////////////////// */
  .account-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .account-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #0D2136;
    border-radius: 50px;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  }
  
  .account-modal-message {
    color: #D3E3FD;
  }

  .account-modal-form { 
    display: flex;
    flex-direction: column;
    width: 60%;
  }

  .account-modal-checkbox {
    margin-right: 10px;
  }

  .account-modal-checkbox-label {
    margin-top: 10px;
    color: #D3E3FD;
  }

  .account-modal-button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 10px;
  }

  @media (max-width: 768px) {
    .category-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding-top: 20px;
      border-top: 2px solid black;
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
    }
  }