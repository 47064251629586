/* Footer.css */
.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
    background-color: #031525;
    border-top: 1px solid #4F647E;
    color: #D3E3FD;
    text-align: center;
    padding: 20px 0;
    width: 100%;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap; /* Ensure it wraps on smaller screens */
    width: 100%;
  }
  
  .footer-section {
    margin: 10px;
    min-width: 150px;
  }
  
  .footer-bottom {
    border-top: 1px solid #4F647E;
    padding-top: 15px;
  }

  /* Footer.css */
.social-links {
    display: flex;
    justify-content: center;
    gap: 10px; /* Adjust the space between icons */
  }
  
  .social-links a {
    color: #D3E3FD; /* Icon color */
    font-size: 24px; /* Icon size */
    transition: color 0.3s ease;
  }
  
  .social-links a:hover,
  .social-links a:focus {
    color: #4b7bec; /* Icon hover color */
  }

  /* Footer.css */
.link-color {
  color: #D3E3FD; /* Replace with your desired color */
}

/* You might want to add :hover, :active, and :visited states as well */
.link-color:hover {
  color: #4F647E; /* Darker red for hover state */
}


  
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: row;
      align-items: center;
      padding-bottom: 10px;
    }
  
    .footer-section {
      margin-bottom: 10px;
    }
  }

  @media (max-width: 448px) {
    .footer-content {
      flex-direction: row;
      align-items: center;
      padding-bottom: 10px;
    }
  
    .footer-section {
      margin-bottom: 10px;
    }
  }
  
  @media (max-width: 390px) {
    .footer-content {
      flex-direction: row;
      align-items: center;
      padding-bottom: 10px;
    }
  
    .footer-section {
      margin-bottom: 10px;
    }
  }