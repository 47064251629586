@keyframes dropButton {
  0% {
    transform: translateY(-100vh); /* Start above the screen */
    opacity: 0;
  }
  100% {
    transform: translateY(0); /* End at the button's final position */
    opacity: 1;
  }
}

.earnGameContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background: #031525;
}

.outer-game-spikes {
  --spike-color: #6ab5e7;
  --spike-width: 1100px;
  --spike-height: 290px;
  position: relative;
  background: linear-gradient(
    to right,
    #031525,
    #031525
  );
  
 /* padding-block: 40px; */
 
}

.outer-game-spikes::before, .outer-game-spikes::after {
  content: '';
  position: absolute;
  width: 100%;
  height: var(--spike-height);
  background: var(--spike-color);
  -webkit-mask-image :url('../../assets/circle.svg');
  -webkit-mask-size: var(--spike-width) var(--spike-height);
  
  /* -webkit-mask-repeat: no-repeat; */
}

.outer-game-spikes::before {
  z-index: -1;
  content: '';
  position: absolute;
  top: 0;
  right: 0; /* Align to the right */
  width: calc(var(--spike-width) / 4); /* Adjust the width as needed, making it smaller to fit the corner */
  height: var(--spike-height);
  background: var(--spike-color);
  -webkit-mask-image: url('../../assets/circle.svg');
  -webkit-mask-size: contain; /* Adjust to fit the mask inside the defined width and height */
  -webkit-mask-position: top right; /* Align the mask to the top right corner */
  -webkit-mask-repeat: no-repeat;
  transform:  translate(30%, -22%); /* Rotate and adjust positioning */
}



.outer-game-spikes::after{
  z-index: -1;
  content: '';
  position: absolute;
  bottom: 0;
  left: 0; /* Align to the right */
  width: calc(var(--spike-width) / 4); /* Adjust the width as needed, making it smaller to fit the corner */
  height: var(--spike-height);
  background: var(--spike-color);
  -webkit-mask-image: url('../../assets/circle.svg');
  -webkit-mask-size: contain; /* Adjust to fit the mask inside the defined width and height */
  -webkit-mask-position: top right; /* Align the mask to the top right corner */
  -webkit-mask-repeat: no-repeat;
  transform:  translate(-30%, 22%); /* Rotate and adjust positioning */
}

.game-container {
  z-index: 0;
  overflow: hidden;
  display: flex;
    flex-direction: column;
    align-items: center;
  width: auto;
  height: 80%;
  padding: 10px;
  background-color: #031525; 
  border: 2px solid #4F647E;
  border-radius: 20px;
  box-shadow: 0px 15px 40px #4F647E;
  
}

.game-spikes {
  --spike-color: #031525;
  --spike-width: 100%;
  --spike-height: auto;
  position: relative;
  background:
    linear-gradient(to right, #D9EEF9 1px, transparent 1px),
    linear-gradient(to bottom, #D9EEF9 1px, transparent 1px);
  background-size: 20px 20px;
 padding-block: 40px;
}

.game-spikes::before, .game-spikes::after {
  content: '';
  position: absolute;
  width: 100%;
  height: var(--spike-height);
  background: var(--spike-color);
  /* -webkit-mask-image :url('../../assets/trapezoid.svg'); */
  /* -webkit-mask-size: var(--spike-width) var(--spike-height); */

}

.game-spikes::before{
  top: 0
}

.game-spikes::after{
bottom: 0;
/* transform: rotate(.5turn); */
}

.limit-badge-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.limit-badge {
  background-color: #ffcc00; /* Yellow background color */
  color: black; /* Black text color */
  padding: 8px;
  border-radius: 50%; /* Circular badge */
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  width: 24px; /* Width of the badge */
  height: 24px; /* Height of the badge */
  cursor: pointer;
}

.limit-info-blurb {
  position: absolute;
top: 30%; /* positions below the badge */
left: 60%;
transform: translateX(-50%); /* center aligns the tooltip */
width: 170px; /* adjust as needed */
background-color: black;
color: white;
text-align: center;
border-radius: 6px;
padding: 5px 10px;
z-index: 1; /* ensure it's above other elements */
}

.game-achievement-container{
  width: 100%;
}

.achievement-title-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.game-achievement-badge {
  max-width: 80px; /* Adjust as necessary */
  max-height: 100px;
  display: block; /* To remove any extra space below the image */
}
.game-badge-container{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100px;
  height: auto;
}
.game-badge-title{
  text-align: center;
  color: #D3E3FD;
  font-size: 15px;
  font-weight: bold;
}
.game-badge-title2{
  text-align: center;
  color: #0D2136;
  font-size: 15px;
  font-weight: bold;
}
.grade-level-text {
  text-align: center;
  
  color: #D3E3FD;
  background-color: #0D2136;
  font-size: 30px;
  width: 150px;
  height: 50px;
  border: 2px solid #4F647E;
  border-radius: 20px;
  padding-left: 5px;
  padding-right: 5px;

}


/* Progress Bar CSS */
.progress-bar-outer {
  position: relative;
width: 100%;
margin-bottom: 5px;
background-color: #eee;
border-radius: 4px;
box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
}

.progress-bar-inner {
  position: relative;
height: 20px; /* Adjust height as necessary */
background-color: #3D9970;
border-radius: 4px;
line-height: 20px; /* Match height for vertical centering */

font-weight: bold;
text-align: center;
transition: width 0.4s ease-in-out;
text-align: center;
}

.progress-bar-text {
  position: absolute;
  color: #0D2136;
  font-weight: bold;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3; /* Higher index to ensure visibility */
  text-align: center;
}

.game-earnings-container{
display: flex;
flex-direction: row;
justify-content: space-around;
align-items: flex-start;
margin-top: 10px;
width: 100%;
}
.game-earnings-container-left{
display: flex;
flex-direction: row;
}
.faCogIcon {
  cursor: pointer;
  width: 30px;
  height: 30px;
  padding-right: 20px;
  color: #0D2136;
}

.faCogIcon:hover {
  
  color: #D3E3FD;
}

.faCogIcon:active {
  transform: scale(1.3);
}

.game-avatar {
  width: 50px;
  height: 50px;
}

.game-username {
  color: #D3E3FD;
  text-align: center;
}

.indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  /* Add more styling as needed */
}


.correctIndicator {
  color: rgb(4, 250, 4);
  font-size: 200px;
  text-align: 'center';
  /* Other styling as needed */
}

.incorrectIndicator {
  color: red;
  font-size: 200px;
  text-align: 'center';
  /* Other styling as needed */
}


.problemContainer {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 3px;
  margin-bottom: 3px;
  padding-top: 20px;
  padding-bottom: 30px;
  box-shadow: inset 0 0 30px 12px #94bcd3;
  /* padding-block: 30px; */
  background-color: #F3FBFD;
  /* border: 2px solid #cb997e; */
  border-radius: 20px;
  width: 100%;
  height: 100%;
}



.numberPad {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  width: 100%;
  height: 100%;
  font-family: 'MathJax_Main';
  /* border: 2px solid black; */
  
}

.input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #031525;
  font-size: 30px;
}

.fraction-line {
  border: 1px solid #031525;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 20px;
}

.gameInput-fraction {
  font-size: 20px;
  text-align: 'center';
  background-color: transparent; /* Make the background transparent */
  border: 1px solid #000; /* Example border: black and 1px wide */
  width: 140px;
  height: 30px;
  color: #031525; /* Text color */
  font-family: 'MathJax_Main';
  padding: 10px; /* Padding inside the input */
  margin-left: 20px;
  /* margin-right: 10px; */
  border-radius: 5px; /* Rounded corners for the border */
  outline: none; /* Removes the default focus outline */
}

.gameInput-fraction::placeholder {
  color: #031525; /* Change to your desired color */
  font-family: 'MathJax_Main';
  opacity: 1; /* Optional: Adjust the opacity of the placeholder text */
}

.gameInput-fraction:focus {
  border: 2px solid black;
}

.gameInput {
  font-size: 15px;
  text-align: 'center';
  background-color: transparent; /* Make the background transparent */
  border: 1px solid #031525; /* Example border: black and 1px wide */
  width: 120px;
  height: 40px;
  color: #031525; /* Text color */
  font-family: 'MathJax_Main';
  padding: 10px; /* Padding inside the input */
  margin-left: 5px;
  margin-right: 10px;
  border-radius: 5px; /* Rounded corners for the border */
  outline: none; /* Removes the default focus outline */
}

.gameInput::placeholder {
  color: #031525; /* Change to your desired color */
  font-family: 'MathJax_Main';
  opacity: 1; /* Optional: Adjust the opacity of the placeholder text */
}

.gameInput:focus {
  border: 2px solid black;
}


.prefix-text-container, .postfix-text-container {
  font-size: 20px;
}


.gameButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  margin-top: 10px;
  padding-top: 5px;
  padding-bottom: 2px;
  width: 100%;
}

.game-button {
  background-color: #0D2136;
  border-radius: 10px;
  color: #D3E3FD;
  font-size: 30px;
  font-family: 'MathJax_Main';
  border: 2px solid #4F647E;
  box-shadow: 0 2px 2px #D3E3FD;
}

.game-button {
  background-color: #0D2136;
  border-radius: 10px;
  color: #D3E3FD;
  font-size: 30px;
  font-family: 'MathJax_Main';
  border: 2px solid #4F647E;
  box-shadow: 0 2px 2px #D3E3FD;
}
.game-button:hover {
  background: linear-gradient(to right, #8AB4F8, #8AB4F8); 
}

.game-button:active {
  transform: scale(1.1) !important;
}

/* .game-button:hover {
    
  background: linear-gradient(to right, #8AB4F8, #8AB4F8); 
} */
.game-button:active {
  background: linear-gradient(to right, #8AB4F8, #8AB4F8); /* Change background color when button is clicked */
}

.topLine {
  text-align: right;
  
}
.problem .number1, .problem .number2, .problem .operation {
  font-family: 'MathJax_Main';
}
.bottomLine {
  text-align: right;
}
.number1,.number2 {
  text-align: right;
  margin-left: 50px;
}
.problem {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}
.problem-stacked {
  font-size: 35px;
  /* font-weight: bold; */
  border-bottom: 2px solid #031525;
  color: #031525;
  /* margin-bottom: 10px; */
}
.problem-topic-container {
  /* font-weight: bold; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #031525;
  font-weight: bold;
  font-size: 25px;
}
.problem-topic {
  color: #031525;
  font-weight: bold;
  font-size: 25px;
}
.reveal-badge-container {
  position: absolute; /* Position relative to the parent container */
  right: 0; /* Align to the far right */
  top: 70%; 
  /* transform: translateY(-40%);  */
  margin-right: 10px;
}
.reveal-badge {
  background-color: #006eff; /* Yellow background color */
  color: #D3E3FD; /* Black text color */
  padding: 8px;
  border-radius: 50%; /* Circular badge */
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  width: 60px; /* Width of the badge */
  height: 50px; /* Height of the badge */
  cursor: pointer;
}

.reveal-badge:hover {
  background-color: #031525; /* Yellow background color */
  color: #D3E3FD;
}

.reveal-badge:active {
  background-color: #006eff; /* Yellow background color */
  color: #D3E3FD;
}

.reveal-blurb {
  position: absolute;
top: 30%; /* positions below the badge */
left: -90%;
/* transform: translateX(0%);  */
width: auto; /* adjust as needed */
font-size: 16px;
background-color: #031525;
color: #D3E3FD;
text-align: center;
border-radius: 6px;
padding: 5px;
z-index: 1000; /* ensure it's above other elements */
}
.reveal-count {
  position: absolute;
top: -20%; /* positions below the badge */
left: 50%;
/* transform: translateX(0%);  */
width: 30px; /* adjust as needed */
height: 25px;
font-size: 15px;
font-weight: bold;
background-color: black;
border-radius: 100px;
color: #ffcc00;
text-align: center;
padding: 1px 0px;
z-index: 1000; /* ensure it's above other elements */
}
.seventhProblem {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

/* .seventh-problem-topic {
  font-weight: bold;
  color: #031525;
  font-weight: bold;
  font-size: 25px;
} */
.seventhLine {
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  text-wrap: wrap;
  color: #031525;
  font-size: 25px;
  font-weight: bold;
  
}
/* .seventh {
  text-align: center;
  text-wrap: wrap;
  color: #031525;
  font-size: 30px;
  font-weight: bold;
  border-bottom: 2px solid #000000;
} */

.eighthProblem {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

/* .eighth-problem-topic {
  font-weight: bold;
  color: #031525;
  font-weight: bold;
  font-size: 25px;
} */
.eighthLine {
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  text-wrap: wrap;
  color: #031525;
  font-size: 40px;
  font-weight: bold;
  
}
/* .eighth {
  text-align: center;
  text-wrap: wrap;
  color: #031525;
  font-size: 50px;
  font-weight: bold;
  border-bottom: 2px solid #000000;
} */

.fractionProblem {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

/* .fraction-problem-topic {
  font-weight: bold;
  color: #031525;
  font-weight: bold;
  font-size: 25px;
} */
.fractionLine {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 50px;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  text-wrap: wrap;
  color: #031525;
  font-weight: bold;
  
}

.fraction-inputs {
  display: flex;
    flex-direction: column;
}
/* .fraction {
  text-align: center;
  text-wrap: wrap;
  color: #031525;
  font-size: 30px;
  font-weight: bold;
  border-bottom: 2px solid #000000;
} */

.geometryProblem {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  height: auto;
}

/* .geometry-problem-topic {
  font-weight: bold;
  color: #031525;
  font-weight: bold;
  font-size: 25px;
} */
.geometryLine {
  display: flex;
  align-items: center;
  text-align: center;
  text-wrap: wrap;
  color: #031525;
  font-size: 25px;
  font-weight: bold;
  /* border-bottom: 2px solid #000000; */
  
}
/* .geometry {
  text-align: center;
  text-wrap: wrap;
  color: #031525;
  font-size: 30px;
  font-weight: bold;
  border-bottom: 2px solid #000000;
} */

.algebraProblem {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

/* .algebra-problem-topic {
  font-weight: bold;
  color: #031525;
  font-weight: bold;
  font-size: 25px;
} */
.algebraLine {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 30px;
  text-align: center;
  text-wrap: wrap;
  color: #031525;
  font-weight: bold;
  /* border-bottom: 2px solid #ffffff; */
}
/* .algebra {
  text-align: center;
  text-wrap: wrap;
  color: #031525;
  font-size: 30px;
  font-weight: bold;
  border-bottom: 2px solid #ffffff;
} */

.algebraRationalProblem {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.algebraRationalLine {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 50px;
  text-align: center;
  text-wrap: wrap;
  color: #031525;
  font-weight: bold;
  /* border-bottom: 2px solid #ffffff; */
}

.algebraSystemsProblem {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.algebraSystemsLine {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
  text-align: center;
  text-wrap: wrap;
  color: #031525;
  font-weight: bold;
  /* border-bottom: 2px solid #ffffff; */
}

/* .algebraSequenceProblem {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.algebraSequenceLine {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 30px;
  text-align: center;
  text-wrap: wrap;
  color: #031525;
  font-weight: bold;
  border-bottom: 2px solid #ffffff;
} */

.calculusProblem {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

/* .calculus-problem-topic {
  font-weight: bold;
  color: #031525;
  font-weight: bold;
  font-size: 25px;
} */
.calculusLine {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 30px;
  text-align: center;
  text-wrap: wrap;
  color: #031525;
  font-weight: bold;
  /* border-bottom: 2px solid #ffffff; */
  
}
/* .calculus {
  text-align: center;
  text-wrap: wrap;
  color: #031525;
  font-size: 30px;
  font-weight: bold;
  border-bottom: 2px solid #ffffff;
} */

.question1 {
  text-align: center;
  text-wrap: wrap;
  color: #031525;
  font-size: 30px;
  font-weight: bold;
  
}
.question2 {
  text-align: center;
  text-wrap: wrap;
  color: #031525;
  font-size: 30px;
  font-weight: bold;
  border-bottom: 2px solid #031525;
}

.svg-container {
  height: 100px; /* or any other fixed height you prefer */
  width: 100%; /* this ensures the container takes the full width of its parent */
  display: flex;
  justify-content: center; /* centers the SVG in the container */
  align-items: center; /* centers the SVG vertically */
}

/* Reveal -MODal //////////////////////// */

.revealModalOverlay {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}

.revealModalContent {
  background-color: #031525;
  color: #8AB4F8;
  padding: 20px;
  border-radius: 5px;
  width: 350px;
}

.reveal-modal-title {
  font-size: 25px;
  text-align: center;
  color: #8AB4F8;
}

.reveal-modal-info {
  font-size: 18px;
  text-align: center;
}

.reveal-button-container {
  width: 100%;
}

.reveal-button {
  background-color: #3D9970;
  padding: 5px;
  border-radius: 10px;
  color: #fff;
  font-size: 20px;
  width: 100%;
}

/* Settings -MODal //////////////////////// */

#difficulty-select {
margin-bottom: 10px;
}
.difficulty-label {
  color: #8AB4F8;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  border-top: 1px solid #4F647E;
}
.difficulty-select {
  font-size: 20px;
  border-radius: 10px;
  text-align: center;
  color: #D3E3FD;
  background: #0D2136;
  border-color: #4F647E;
}
.checkbox {
  width: 20px; /* or any size you prefer */
  height: 20px; /* or any size you prefer */
  cursor: pointer; 
}

.checkbox-label {
  
  margin-left: 8px;  
  cursor: pointer; 
}

.checkbox-options {
  color: #D3E3FD;
  font-size: 17px;
  display: flex;
  align-items: center; /* Aligns the checkbox and label text vertically */
  margin-bottom: 5px;
}

.sound-toggle {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  border-top: 1px solid #4F647E;
}

.sound-label {
  color: #8AB4F8;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
}

.save-button-container {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  border-top: 1px solid #4F647E;
}

.save-button {
  background-color: #3D9970;
  padding: 5px;
  border-radius: 10px;
  color: #fff;
  font-size: 20px;
  width: 100%;
}

.save-button:hover {
  background-color: #D3E3FD;
  color: #031525;
}

  .save-button:active {
    background-color: #D3E3FD;
    color: #3D9970;
    
  }

  /* .settings-close-container {
    display: flex;
    justify-content: end;
  } */

  .settings-close {
    float: right;
    cursor: pointer;
    color: #D3E3FD;
    font-size: 35px;
    text-align: center;
  }

.modal-title {
  font-size: 40px;
  text-align: center;
  color: #8AB4F8;
}

.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: #031525;
  padding: 20px;
  border-radius: 5px;
  width: 350px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  margin-left: 5px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


/* MILESTONE MODAL/////////////////////////////*/
.achievement-modal-overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.achievement-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  color: white;
}

.achievement-modal-content {
  background-color: #007bff;
}

.achievement-modal-button {
  width: 50%;
  margin-top: 20px;
  background-color: #3d9970;
  border-radius: 10px;
  color: white;
}

.achievement-modal-button:hover {
  background-color: white;
  color: black;
}

.achievement-modal-badge {
  width: 140px;
  height: 110px;
}






@media (max-width: 768px) {
  .earnGameContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    
  }
  .game-container {
   width: 100%;
   height: 100%;
    
  }


  

  .gameInput {
    font-size: 20px;
    text-align: 'center';
    
  }

  .gameButtonContainer {
    width: 100%;
    margin-top: 0px;
    padding-top: 5px;
    padding-bottom: 2px;
  }

  .game-button {
   
    border-radius: 10px;
    
    font-size: 20px;
  
  }
}

/* //////////////////////////////////////////////////////////////////////////////////// */


/* ///////////////////////////////////////////////////////////////////////////////////// */

/* /////////////////////////////////////////////////////////////////////////// */

@media (max-width: 667px) {
  .earnGameContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    
  }

  .game-achievement-container{
    width: 100%;
  }
  
  .achievement-title-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .game-achievement-badge {
    max-width: 60px; /* Adjust as necessary */
    max-height: 80px;
    display: block; /* To remove any extra space below the image */
  }
  
  .game-badge-title{
    text-align: center;
    color: #D3E3FD;
    font-size: 12px;
    font-weight: bold;
  }
  .game-badge-title2{
    text-align: center;
    color: #0D2136;
    font-size: 12px;
    font-weight: bold;
  }
  .grade-level-text {
   
    font-size: 25px;
    width: 150px;
    height: 40px;
    
    padding-left: 5px;
    padding-right: 5px;
  
  }

  .game-container {
   width: 100%;
   height: 100%;
    
  }

  .numberPad {
    height: 85%;
    
  }
  
  .problem-topic {
    margin-bottom: 0px;
    font-size: 20px;
  }
  .calculusLine {
    
    font-size: 25px;
    
    /* border-bottom: 2px solid #ffffff; */
    
  }

  .geometryProblem {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .geometry-shape {
width: 40%;
  }

  .geometryLine {
    display: flex;
    align-items: center;
    text-align: center;
    text-wrap: wrap;
    color: #031525;
    font-size: 18px;
    font-weight: bold;
    /* border-bottom: 2px solid #000000; */
    
  }

  .algebraProblem {
    
    margin-bottom: 0px;
  }
  
  .algebraLine {
   
    font-size: 25px;
    
    /* border-bottom: 2px solid #ffffff; */
  }
  
  
  .algebraRationalProblem {
   
    margin-bottom: 10px;
  }
  
  .algebraRationalLine {
    
    font-size: 40px;
    
    /* border-bottom: 2px solid #ffffff; */
  }
  

  .problemContainer {
    
    padding-top: 5px;
    padding-bottom: 50px;
    /* width: 100%;
    height: 100%; */
  }
  

  .gameButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    margin-top: 0px;
    padding-top: 5px;
    padding-bottom: 2px;
  }

  .game-button {
    
    font-size: 20px;
  
  }
}

@media (max-width: 448px) {
  .earnGameContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    
  }
  .game-container {
   width: 100%;
   height: 100%;
    
  }

  .game-achievement-container{
    width: 100%;
  }
  
  .achievement-title-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .game-achievement-badge {
    max-width: 80px; /* Adjust as necessary */
    max-height: 100px;
    display: block; /* To remove any extra space below the image */
  }
  
  .game-badge-title{
    text-align: center;
    color: #D3E3FD;
    font-size: 15px;
    font-weight: bold;
  }
  .game-badge-title2{
    text-align: center;
    color: #0D2136;
    font-size: 15px;
    font-weight: bold;
  }
  .grade-level-text {
    
    font-size: 30px;
    width: 150px;
    height: 50px;

    padding-left: 5px;
    padding-right: 5px;
  
  }

  /* .game-spikes {
  
    --spike-width: 100%;
    --spike-height: 15px;
    position: relative;
    
  background-size: 20px 20px;
   padding-block: 40px;
  }
  
  .game-spikes::before, .game-spikes::after {
    content: '';
    position: absolute;
    width: 100%;
    height: var(--spike-height);
    background: var(--spike-color);
    -webkit-mask-image :url('../../assets/trapezoid.svg');
    -webkit-mask-size: var(--spike-width) var(--spike-height);
    
    /* -webkit-mask-repeat: no-repeat; */
  /* } */
  
  /* .game-spikes::before{
    top: 0
  }
  
  .game-spikes::after{
  bottom: 0;
  transform: rotate(.5turn);
  } */ 
  
.game-earnings-container{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 10px;
  width: 100%;
}

  .faCogIcon {
    cursor: pointer;
    width: 30px;
    height: 30px;
    padding-right: 20px;
    
  }
  

  .problemContainer {
    
    padding-top: 10px;
    padding-bottom: 30px;
    width: 100%;
    height: 100%;
  }
 
  .problem {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3px;
  }
  .problem-stacked {
    font-size: 40px;
    /* font-weight: bold; */
    border-bottom: 2px solid #031525;
    color: #031525;
    /* margin-bottom: 10px; */
  }
  .problem-topic {
    /* font-weight: bold; */
    color: #031525;
    font-weight: bold;
    font-size: 20px;
  }
  .seventhProblem {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
  
 
  .seventhLine {
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
    text-wrap: wrap;
    color: #031525;
    font-size: 25px;
    font-weight: bold;
    
  }
  
  
  .eighthProblem {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
  
 
  .eighthLine {
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
    text-wrap: wrap;
    color: #031525;
    font-size: 40px;
    font-weight: bold;
    
  }
 
  
  .fractionProblem {
    margin-bottom: 0px;
  }
  
 
  .fractionLine {
    font-size: 50px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
    
  }

  .fraction-inputs {
    

  }

  .gameInput-fraction {
    font-size: 18px;
    height: 20px;
  }
 
  .geometry-shape {
    width: 50%;
    
      }

  /* .svg-container{
    height: 70px; 
    margin-bottom: 5px;
    width: 100%;
  } */

  .geometryProblem {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0px;
  }
  
 
  .geometryLine {
    display: flex;
    align-items: center;
    text-align: center;
    text-wrap: wrap;
    color: #031525;
    font-size: 25px;
    font-weight: bold;
    /* border-bottom: 2px solid #000000; */
    
  }
  
  
  .algebraProblem {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px;
  }
  
  
  .algebraLine {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 30px;
    /* border-bottom: 2px solid #ffffff; */
  }
 
  
  .algebraRationalProblem {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .algebraRationalLine {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 50px;
    text-align: center;
    text-wrap: wrap;
    color: #031525;
    font-weight: bold;
    /* border-bottom: 2px solid #ffffff; */
  }
  
  
  
  .calculusProblem {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
  
  
  .calculusLine {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 30px;
    text-align: center;
    text-wrap: wrap;
    color: #031525;
    font-weight: bold;
    /* border-bottom: 2px solid #ffffff; */
    
  }
  
  
  .question1 {
    text-align: center;
    text-wrap: wrap;
    color: #031525;
    font-size: 30px;
    font-weight: bold;
    
  }
  .question2 {
    text-align: center;
    text-wrap: wrap;
    color: #031525;
    font-size: 30px;
    font-weight: bold;
    border-bottom: 2px solid #031525;
  }

  .numberPad {
    
    width: 100%;
    height: 100%;
    /* border: 2px solid black; */
    
  }

  .gameInput {
    font-size: 20px;
    text-align: 'center';
    
  }

  .gameButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    margin-top: 0px;
    padding-top: 5px;
    padding-bottom: 2px;
  }

  .game-button {
   
    border-radius: 10px;
    
    font-size: 25px;
  
  }
}

/* //////////////////////// */

@media (max-width: 390px) {
  .earnGameContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    
  }
  .game-container {
   width: 100%;
   height: 100%;
    
  }

  .game-achievement-container{
    width: 100%;
  }
  
  .achievement-title-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .game-achievement-badge {
    max-width: 80px; /* Adjust as necessary */
    max-height: 100px;
    display: block; /* To remove any extra space below the image */
  }
  
  .game-badge-title{
    text-align: center;
    color: #D3E3FD;
    font-size: 15px;
    font-weight: bold;
  }
  .game-badge-title2{
    text-align: center;
    color: #0D2136;
    font-size: 15px;
    font-weight: bold;
  }
  .grade-level-text {
    text-align: center;
    
    
    font-size: 24px;
    width: 30vw;
    height: 7vh;
    border-radius: 20px;
    padding-left: 5px;
    padding-right: 5px;
  
  }

  
.game-earnings-container{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 0px;
  width: 100%;
}

  .faCogIcon {
    cursor: pointer;
    width: 30px;
    height: 30px;
    padding-right: 20px;
    
  }
  

  .problemContainer {
    
    padding-top: 0px;
    padding-bottom: 40px;
    width: 100%;
    height: 100%;
  }
  /* .problm-topic{
    font-size: 25px;
  } */
  .problem {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3px;
  }
  .problem-stacked {
    font-size: 30px;
    /* font-weight: bold; */
    border-bottom: 2px solid #031525;
    color: #031525;
    /* margin-bottom: 10px; */
  }
  .problem-topic {
    /* font-weight: bold; */
    color: #031525;
    font-weight: bold;
    font-size: 20px;
  }
  .seventhProblem {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
  
 
  .seventhLine {
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
    text-wrap: wrap;
    color: #031525;
    font-size: 25px;
    font-weight: bold;
    
  }
  
  
  .eighthProblem {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
  
 
  .eighthLine {
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
    text-wrap: wrap;
    color: #031525;
    font-size: 40px;
    font-weight: bold;
    
  }
 
  
  .fractionProblem {
    margin-bottom: 0px;
  }
  
 
  .fractionLine {
    font-size: 40px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
    
  }

  .fraction-inputs {
    

  }

  .gameInput-fraction {
    font-size: 18px;
    height: 20px;
  }
 
  .geometry-shape {
    width: 50%;
    
      }

  /* .svg-container{
    height: 70px; 
    margin-bottom: 5px;
    width: 100%;
  } */

  .geometryProblem {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0px;
  }
  
 
  .geometryLine {
    display: flex;
    align-items: center;
    text-align: center;
    text-wrap: wrap;
    color: #031525;
    font-size: 20px;
    font-weight: bold;
    /* border-bottom: 2px solid #000000; */
    
  }
  
  
  .algebraProblem {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px;
  }
  
  
  .algebraLine {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 27px;
    /* border-bottom: 2px solid #ffffff; */
  }
 
  
  .algebraRationalProblem {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .algebraRationalLine {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 50px;
    text-align: center;
    text-wrap: wrap;
    color: #031525;
    font-weight: bold;
    /* border-bottom: 2px solid #ffffff; */
  }

  .algebraSystemsProblem {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }

  .algebraSystemsLine {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 22px;
    text-align: center;
    text-wrap: wrap;
    color: #031525;
    font-weight: bold;
    /* border-bottom: 2px solid #ffffff; */
  }
  
  
  
  .calculusProblem {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
  
  
  .calculusLine {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 26px;
    text-align: center;
    text-wrap: wrap;
    color: #031525;
    font-weight: bold;
    /* border-bottom: 2px solid #ffffff; */
    
  }
  
  
  .question1 {
    text-align: center;
    text-wrap: wrap;
    color: #031525;
    font-size: 30px;
    font-weight: bold;
    
  }
  .question2 {
    text-align: center;
    text-wrap: wrap;
    color: #031525;
    font-size: 30px;
    font-weight: bold;
    border-bottom: 2px solid #031525;
  }

  .numberPad {
    
    width: 100%;
    height: 100%;
    /* border: 2px solid black; */
    
  }

  .gameInput {
    font-size: 16px;
    text-align: 'center';
    height: 30px;
    
  }

  .gameButtonContainer {
    width: 100%;
    margin-top: 0px;
    padding-top: 5px;
    padding-bottom: 2px;
  }

  .game-button {
    font-size: 20px;
  padding: 0px;
  }
}