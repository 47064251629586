.verify-screen-container {
    width: 100%;
    height: 100vh;
    background-color: #031525;
  }

  .verify-screen-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; 
    padding: 20px;
    background-color: #031525;
    
  }
  
  .verify-screen-title {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    color: #3d9970;
    margin-bottom: 20px;
  }
  
  .verify-screen-description {
    font-size: 20px;
    /* font-weight: bold; */
    text-align: center;
    color: #D3E3FD;
    margin-bottom: 60px;
  }

  .verify-screen-suggestion {
    font-size: 20px;
    /* font-weight: bold; */
    text-align: center;
    color: #D3E3FD;
    margin-bottom: 20px;
  }

  .verify-button {
    background-color: #3d9970;
    color: white;
    padding: 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: block;
    width: 60%;
    margin: auto;
    font-size: 18px;
    margin-bottom: 20px;
    
  }

  .verify-screen-loading {
    font-size: 20px;
    /* font-weight: bold; */
    text-align: center;
    color: #D3E3FD;
    margin-bottom: 20px;
  }

  .verify-screen-email {
    font-size: 20px;
    /* font-weight: bold; */
    text-align: center;
    color: #00B69F;
    margin-bottom: 20px;
  }

  .verify-screen-success {
    font-size: 20px;
    /* font-weight: bold; */
    text-align: center;
    color: #00B69F;
    margin-bottom: 20px;
  }

  .verify-screen-error {
    font-size: 20px;
    /* font-weight: bold; */
    text-align: center;
    color: #D11D5E;
    margin-bottom: 20px;
  }
  
  .verify-screen-support {
    font-size: 20px;
    /* font-weight: bold; */
    text-align: center;
    color: #D3E3FD;
    margin-top: 200px;
    
  }
  