/* Leaderboard.css */
@keyframes dropAvatar {
  0% {
    transform: translateY(-100vh); /* Start off-screen above the view */
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    transform: translateY(0); /* End at the avatar's final position */
    opacity: 1;
  }
}
.avatarFirst,
.avatarSecond,
.avatarThird {
  animation: dropAvatar 1s ease-out forwards; /* Adjust duration as needed */
}



@keyframes cascadeEntry {
  from {
    opacity: 0;
    transform: translateX(100%); /* Start from a bit lower */
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}


.leaderboard-main-container {
    background-color: #031525;
    width: 100%;
    
  }

.leaderboardContainer {
  height: 100vh;
    padding: 10px;
    background-color: #031525;
    overflow: auto;
  }
  
  .leaderboard-title {
    font-size: 40px;
    font-weight: bold;
    color: #3D9970;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .difficultySelector {
    align-items: center;
    font-size: 30px;
  border-radius: 10px;
  text-align: center;
  color: #D3E3FD;
  background: #0D2136;
  margin-top: 20px;
  margin-bottom: 20px;
  border-color: #4F647E;
  }

  .rank-avatar {
    width: 50px;
    height: 50px;
    border-radius: 20px; /* Circular avatar */
    /* margin-right: 10px; */
    object-fit: cover; /* Ensures the image covers the area without stretching */
  }

  .rank-username {
    font-size: 25px;
    font-weight: bold;
    color: #D3E3FD;
    /* margin-right: 40px; */
    
  }
  
  
  .rankTitle {
    font-size: 30px;
    font-weight: bold;
    color: #D3E3FD;
    text-align: center;
    margin-bottom: 10px;
  }
  
  .podiumContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    /* perspective: 500px;  */
  }

  .podiumPlaces {
    display: flex;
    justify-content: center;
    margin-bottom: 0px;
  }

  .podium-image {
    width: 500px;
    height: 300px;
  }

  .rank-board {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 30px;
    padding: 10px;
    background-color: #1d2847;
    border-color: #e91919;
    
  }
  
  .rankContainer {
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: space-between; */
    /* padding: 10px; */
    /* border-top: 1px solid #ddd; */
    border: 1px solid #4F647E;
    background-color: #031525;
    border-radius: 30px;
    border-color: #4F647E;
    margin-bottom: 10px;
    width: 100%;
    
  }
  
  .itemContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    
  }

  .item-primary-container {
    display: flex;
    flex-direction: row;
    width: auto;

  }

  .item-secondary-container {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    width: 100%;
    
    /* margin-right: 5px; */
  
  /* box-shadow: 10px 8px 2px #4F647E; */
  }

  .item-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    text-align: center;
    width: 100%;
    
  }

  .rank-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    
    width: auto;
    height: 90px;
    margin-right: 5px;
    background: linear-gradient(
    to bottom right,
    #1a61a0,
    #63c75a
  );
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  border-right: 1px solid #D3E3FD;
  /* box-shadow: 10px 8px 2px #4F647E; */
  }

  

  .avatar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-right: 10px;
    width: 70px;
    height: 90px;
    background: linear-gradient(
    to right,
    #bebfc0,
    #142e5e
  );
  }

  .username-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  .score-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    
  }

  .leaderboard-achievement-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;

  }
  
  .rank {
    font-size: 25px;
    margin-right: 10px;
    font-weight: bold;
    color: #D3E3FD;
    width: 30px; /* Fixed width for rank */
    text-align: center;
  }
  
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 20px; /* Circular avatars */
    margin-right: 10px;
    overflow: hidden; /* To ensure the image fits in the circle */
  }
  
  .userInfo {
    flex-grow: 1;
  }
  
  .name {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
  
  .score {
    font-size: 20px;
    font-weight: bold;
    color: #3D9970;
    
  }

  

  .achievement {
    font-size: 18px;
    font-weight: bold;
    color: #D3E3FD;
  }

  
  .leaderboard-achievement-badge {
    max-width: 80px; /* Adjust as necessary */
    max-height: 60px;
    display: block; /* To remove any extra space below the image */
  }
  /* Add styles for podium places */
 
  .avatarFirst {
    width: 120px; /* Adjust as needed */
    height: 120px;
    border-radius: 30px; /* Circular avatars */
    border: 8px outset #F1DA96; /* Border for the avatar */
    padding: 5px;
  }

  .avatarSecond {
    width: 100px; /* Adjust as needed */
    height: 100px;
    border-radius: 30px; /* Circular avatars */
    border: 8px outset #F3F4F1; /* Border for the avatar */
    padding: 5px;
  }

  .avatarThird {
    width: 80px; /* Adjust as needed */
    height: 80px;
    border-radius: 30px; /* Circular avatars */
    border: 8px outset #D7A462; /* Border for the avatar */
    padding: 5px;
  }
  
  .usernamePodium {
    color: #D3E3FD;
    font-weight: bold;
  }

  .podiumPosition {
    color: #D3E3FD;
    font-size: 30px;
    font-weight: bold;
  }
  

  
  .podiumFirst {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* height: auto; */
    margin-right: 30px;
    
    
  }
  
  .podiumSecond {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* height: 75px; */
    padding-top: 20px;
    margin-right: 30px;
  }
  
  .podiumThird {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-top: 20px;
    /* height: 50px; */

  }
  
  
  @media (max-width: 448px) {
    .avatarFirst {
    width: 120px; /* Adjust as needed */
    height: 120px;
    
  }

  .avatarSecond {
    width: 100px; /* Adjust as needed */
    height: 100px;
    
    
  }
  .avatarThird {
    width: 80px; /* Adjust as needed */
    height: 80px;
    
    
  }

  .podiumPlaces {
    /* margin-bottom: 30px; */
  }
  
  .podium-image {
    width: 100%;
    height: 200px;
  }
  .usernamePodium {
    color: #D3E3FD;
    font-weight: bold;
  }
  .rankContainer {

    width: 100%;
  }

  .itemContainer {
    width: 100%;

  }
  
.rank-username{
  font-size: 18px;
  overflow: hidden;
  text-overflow: 'ellipsis';
  white-space: nowrap;
}

.username-container {
  width: 100%;
  overflow: hidden;
  text-overflow: 'ellipsis';
  white-space: nowrap;
}
.score{
  font-size: 16px;
}

.score-container {
  display: flex;
  
  justify-content: flex-end;
  
  }

  .achievement {
    font-size: 14px;
    
  }

  .leaderboard-achievement-badge {
    max-width: 70px; /* Adjust as necessary */
    max-height: 50px;
    }

.leaderboard-achievement-container {
  display: flex;
  align-items: flex-end;
  margin-right: 15px;
}

}

@media (max-width: 390px) {
  .avatarFirst {
    width: 120px; /* Adjust as needed */
    height: 120px;
    
  }

  .avatarSecond {
    width: 100px; /* Adjust as needed */
    height: 100px;
    
    
  }
  .avatarThird {
    width: 80px; /* Adjust as needed */
    height: 80px;
    
    
  }

.podiumPlaces {
  /* margin-bottom: 30px; */
}

.podium-image {
  width: 100%;
  height: 200px;
}
.usernamePodium {
  color: #D3E3FD;
  font-weight: bold;
}
.rankContainer {

  width: 100%;
}

.itemContainer {
  width: 100%;

}

.rank-username{
font-size: 16px;
overflow: hidden;
text-overflow: 'ellipsis';
white-space: nowrap;
}

.username-container {
width: 100%;
overflow: hidden;
text-overflow: 'ellipsis';
white-space: nowrap;
}
.score{
font-size: 14px;
}

.score-container {
display: flex;

justify-content: flex-end;

}

.achievement {
  font-size: 12px;
  
}

.leaderboard-achievement-badge {
  max-width: 70px; /* Adjust as necessary */
    max-height: 50px;
  }

.leaderboard-achievement-container {
/* margin-left: 10px; */
margin-right: 15px;
}

}