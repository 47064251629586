.support-container {
    width: 100%;
    height: 100%;
    background-color: #f1faee;
  }

  .terms-title {
    text-align: center;
  }

  .terms-bullets {
    color: black;
  }