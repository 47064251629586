.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    align-content: center;
    width: 100%; 
    height: 100%; 
    background-color: #fff;
  }
  .login-title {
    font-size: 60px;
    /* color: #709775; */
    color: #3D9970;
    margin-bottom: 40px;

  }
  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    padding: 10px;
    /* border: 1px solid #000;  */
    
  }
  .form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    
  }
  .login-label {
    font-size: 20px;
    color: black;

  }
  .login-input {
    font-size: 20px;
      text-align: 'center';
      background-color: transparent; /* Make the background transparent */
      
      margin: 5px;
      color: black; /* Text color */
      padding: 10px;
      width: 70%; /* Padding inside the input */
       /* Font size */
      border-radius: 5px; /* Rounded corners for the border */
      outline: none;
  }
  .login-button {
    background-color: #233565;
    padding: 5px;
    border-radius: 10px;
    color: #fff;
    font-size: 30px;
    width: 100%;
    
  }
  .login-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 30px;
    width: 70%;
  }

  .forgot-container {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 70%;
    text-align: center;
  }

  .sign-up-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .forgot-link {
    margin-left: 10px;
  }


  @media (max-width: 768px) {}

  @media (max-width: 867px) {
    .form-container {
      width: 100%;
    }
  }